:local {

  .pagination-wrapper {
    position: absolute;
    bottom: 0;
  }

  .wrapper {
    height: 100%;
    position: relative;
  }

  .header-wrapper {
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 57px;
    display: flex;
    justify-content: space-between;
  }

  .patients-section {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 57px;
  }

  .new-patient-link-wrapper {
    display: inline-block;
    margin-left: 30px;
    vertical-align: middle;
    font-size: 18px;
  }

  .search-wrapper {
    width: 75%;
  }

  .import {
    padding-left: 40px;
    padding-right: 40px;
  }
}
