:local {
  .wrapper {
    cursor: pointer;
    &--active {
      background-color: #d5ebf4 !important;
    }

    &:hover .controls {
      opacity: 1;
    }
  }

  .controls {
    opacity: 0;
    transition: opacity .3s ease;
  }

  .button {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    border: 1px solid;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;

    &--edit {
      composes: button;

      border-color: #2f9dc6;
      background-image: url('edit-icon2.png');
      background-size: 57%;
    }

    &--remove {
      composes: button;

      border-color: #e75566;
      background-image: url('trashbin.png');
      margin-left: 10px;
      background-size: 50%;
    }
  }
}
