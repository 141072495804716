@use "src/styles/colors.scss" as *;
@use 'src/styles/mixins.scss' as *;

:root {
  --width: 50px;
  --height: 50px;
  --divider: 1;
  --backColor: tomato;
  --barColor: #ffffff;
}

:local {
  .progress {
    fill: transparent;
    stroke-width: 3px;
    transition: stroke-dashoffset 1s;
  }

  .svg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .base {
    fill: transparent;
    stroke-width: 3px;
    stroke: #828282;
  }

  .border {
    fill: transparent;
    stroke-width: 1px;
    stroke: #828282;
    opacity: 0;
    transition: opacity .3s ease;
  }

  .check {
    transform: scale(0.6) translate(11px, 11px);
  }

  .svg {  }

  /*Ipad Pro 12.9 inch, portrait*/
  //@media only screen
  //and (min-width: 1024px)
  //and (max-height: 1366px)
  //and (-webkit-min-device-pixel-ratio: 1.5) {
  //  .svg {
  //    width: 60px;
  //    height: 60px;
  //  }
  //}

  ///*Ipad Pro 10.5 inch, landscape*/
  //@media only screen
  //and (min-width: 1112px)
  //and (max-height: 834px)
  //and (orientation: landscape)
  //and (-webkit-min-device-pixel-ratio: 2) {
  //  .svg {
  //    width: 28px;
  //    height: 28px;
  //  }
  //}

  /*Macbook*/
  //@media screen
  //  and (min-device-width: 1200px)
  //  and (max-device-width: 1600px)
  //  and (-webkit-min-device-pixel-ratio: 2)
  //  and (min-resolution: 192dpi) {
  //  .svg {
  //    width: 36px;
  //    height: 36px;
  //  }
  //}

  .label {
    display: block;
    width: 100%;
    margin-top: -1px;
    text-align: center;
    font-size: 13px;
  }
}