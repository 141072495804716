:local {
  .wrapper {
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  @media screen and (min-width: 820px) {
    .central-wrapper {
      padding-left: 40px;
      width: 55%;
    }
  }

  .provider-select-wrapper {
    display: flex;
    align-items: baseline;
  }

  .icon-button {
    position: relative;
    height: 40px;
    width: 44px;
    min-width: 0 !important;
    top: 1px;

    img {
      position: absolute;
      max-height: 20px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .central-wrapper {
    display: inline-block;
    width: 50%;
    padding-left: 0;
    vertical-align: top;

    .procedure-label {
      padding-right: 8px;
    }
  }

  .beyond-complete-icon {
    width: 38px;
    height: 38px;
    position: absolute;
    right: 100%;
    margin-right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .select-wrapper {
    position: relative;
    min-width: 140px;
  }

   @media screen and (max-width: 840px) {
     .wrapper {
       .datepicker-wrapper {
         margin-left: 5px;
       }

       .procedure-label {
         margin-right: 0;
       }
     }

   }

  .datepicker-wrapper {
    display: inline-block;
    text-align: right;
    vertical-align: top;
    margin-left: 20px;

    .procedure-label {
      display: inline-block;
      margin-right: 10px;
    }

    div, input {
      height: 40px
    }
  }

  .procedure-code {
    display: inline-block;
    vertical-align: top;
    position: relative;
    
    & > * {
      max-width: 200px;
    }

    :global {
      .Select-value-label {
        max-width: 100%;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    vertical-align: middle;
    text-align: right;
  }

  .procedure-input-container {
    display: inline-block;
  }

  .procedure-label {
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    margin-right: 10px;

    @media screen and (min-width: 700px) {
      font-size: 15px;
    }
  }

  .button {
    text-align: center;
    border: none;
    background: #2f9dc6;
    padding: 10px 15px;
    min-width: 150px;
    color: #ffffff;
    margin-left: 20px;

    &:disabled {
      background: grey;
    }
  }

  .button + .button {
    margin-top: 10px;

    @media screen and (min-width: 700px) {
      margin-top: 0;
    }
  }
}

:global {
  .vitals-element,
  .copyPreviousNoteCharts-element {
    z-index: 1;
  }
}
