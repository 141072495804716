:local {
  .disabled-button-behaviour {
    border: none;
    outline: none;
  }

  .item {
    composes: disabled-button-behaviour;
    display: inline-block;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }

  .radio-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
}
