// :local {
  $linkFontSize: 18px;
  $largeScreenLinkFontSize: 28px;
  $stepIndicatorOffset: 46px;
  $largeScreenStepIndicatorOffset: 66px;

  .registration-disabled-border {
    border: 1px solid #5cbfe3;
    height: 14px;
    position: absolute;
    top: -3px;
    left: -20px;
    right: -20px;

    &--top {
      border-bottom: none;
    }

    &--bottom {
      top: initial;
      bottom: -23px;
      border-top: none;
    }

    @media screen and (min-width: 1000px) {
      left: -8px;
      right: -8px;

      &--bottom {
        bottom: -22px;
      }
    }

    @media screen and (min-width: 1390px) {
      left: -3px;
      right: -3px;

      &--bottom {
        bottom: -28px;
      }
    }
  }

  .coming-soon {
    text-transform: uppercase;
    color: #72cdef;
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0;
    @media screen and (min-width: 1390px) {
      letter-spacing: 0.15em;
      font-size: 14px;
    }
  }

  .top-part {
    width: 100%;

    //ie11 fix
    flex-shrink: 0;
  }

  .linksWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 360px;
    width: 100%;
    padding: 20px 0 $stepIndicatorOffset;
    margin: 0 auto;
  }
  .stepsWrapper {
    position: absolute;
    bottom: -$stepIndicatorOffset;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .link {
    font-size: $linkFontSize;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid #89e0ff;
    color: #89e0ff;
    font-family: 'Open Sans';
    padding: 0 10px;

    //ie10 fix
    display: block;

    &--active {
      cursor: default;
      border: none;
      padding-bottom: 1px;
      color: white;
    }
    &:hover {
      border-bottom-color: transparent;
    }

    &--disabled {
      border-bottom-color: transparent;
      cursor: default;
    }

    @media screen and (min-width: 1390px) {
      font-size: $largeScreenLinkFontSize;
    }
  }

  .signupLink {
    position: relative;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 6;
    min-height: inherit;
    background: #2f9dc6;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2e8cbb+0,429fbd+60,6abcc7+100 */
    background: #2e8cbb; /* Old browsers */
    background: -moz-linear-gradient(top, #2e8cbb 0%, #429fbd 60%, #6abcc7 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #2e8cbb 0%,#429fbd 60%,#6abcc7 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #2e8cbb 0%,#429fbd 60%,#6abcc7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e8cbb', endColorstr='#6abcc7',GradientType=0 ); /* IE6-9 */
    // padding: 24px 15px 24px 15px;
    box-sizing: border-box;
    overflow: auto;
    max-height: 100vh;
    padding-bottom: 30px;

    &--padding {
      padding: 24px;
    }
    
    @media screen and (min-width: 1000px) {
      width: 24%;
    }

    @media screen and (min-width: 1390px) {
      .wrapper--padding {
        padding: 30px;
      }

    }
  }

  .form {
    width: 100%;
    max-width: 360px;
    margin: auto;
    display: flex;
    flex: 1 1 auto;
  }

  .wrapper h1 {
    margin: 0 0 10px;
    font-weight: 400;
    color: white;
  }

  .clinics-list {
    position: absolute;
  }

  @media screen and (min-width: 1000px) {
    .form {
      max-width: 360px;
      // margin: auto;
      // display: flex;
      // flex: 1 1 auto;
      // flex-shrink: 0;
      // flex-direction: column;
      // -webkit-box-flex: 1;
    }
  }

  @media screen and (min-width: 1366px) {
    .wrapper h1 {
      font-size: 48px;
    }
  }

  @media screen and (min-width: 1025px) {
    .linksWrapper {
      padding: 40px 0 $largeScreenStepIndicatorOffset;
    }
    .stepsWrapper {
      bottom: -$largeScreenStepIndicatorOffset;
    }
    .form {
      margin-top: 10px;
    }
  }
// }
