:local {
  .wrapper {
    display: flex;
    align-items: flex-end;
    z-index: 2;
    position: relative;
    flex-wrap: wrap;
  }

  .form-group {
    margin-right: 10px;
    margin-bottom: 20px;
    position: relative;
    &--last {
      margin-right: 30px;
    }
  }

  .label {
    margin: 0 0 3px;
    text-transform: uppercase;
    font-weight: 400;
    display: block;
  }

  .apply-button {
    height: 32px;
    margin-bottom: 20px;
    background: #2f9dc6;
    padding: 5px 30px;
    border: none;
    color: white;
    font-size: 14px;

    &:hover {
      background: rgb(82.0530612245, 178.1346938776, 213.9469387755);
    }

    &:active {
      background: darken(#2f9dc6, 10%);
    }
  }
}
