:local {
  .wrapper {
    min-height: 100vh;
  }

  @media screen and (min-height: 768px) {
    .wrapper {
      min-height: calc(100vh - 48px);
    }
  }
}
