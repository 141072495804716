:local {
  .wrapper {
    display: flex;
    flex: 1 1 auto;
    min-height: 0;
    position: relative;
    padding-left: 80px;
    // ie11 fix
    height: 100%;
    position: relative;
    z-index: 0;
  }

  .step-content {
    width: 100%;
    overflow: hidden;
    background: white;
    display: flex;
    flex: 1 1 auto;
    min-height: 0;
  }
}
