:local {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (min-width: 1025px) {
    .wrapper {
      padding-left: 0;
    }
  }
}
