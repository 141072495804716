:local {
  .loader {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      border: 2px solid #2f9dc6;
      border-top-color: white;
      transform: translate(-50%, -50%);
      animation: rotate infinite linear 2s;
    }
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
