:local {
  .wrapper {
    width: 100%;
    display: flex;
    flex: 1 1 0%;
    overflow: hidden;
    position: relative;
    padding: 15px 15px 0;
    box-sizing: border-box;
    background: #f0f0f0;

    &:empty:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      border: 2px solid #2f9dc6;
      border-top-color: white;
      margin-top: -20px;
      margin-left: -20px;
      animation: rotate infinite linear 2s;
    }
  }

  .wrapper-inner {
    composes: wrapper;

    background-color: #fff;
  }
}


@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
