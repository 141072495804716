:local {
  .title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &--lower-case {
      composes: title;

      text-transform: none;
    }
  }

  .cell-wrapper {
    position: relative;
  }

  .subtitle {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    margin-top: 5px;
    text-transform: none;
    text-align: center;
    display: block;
    font-weight: 300;
    color: #baecff;
    font-size: 14px;
  }
}
