@use 'src/styles/colors.scss' as *;

html,
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  font-size: 0.8em;
}

* {
  box-sizing: border-box;
}

button {
  padding: 0;
}

body {
  font-family: 'Helvetica Neue', HelveticaNeue, 'Open Sans', OpenSans, Arial, sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  overflow: auto;
}

body.has-modal {
  overflow: hidden !important;
}

input[disabled] {
  background-color: #f9f9f9;
}

*:focus {
  outline: none;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

textarea,
input:not([type=checkbox]):not([type=radio]) {
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
}

.section-title {
  margin-bottom: 8px;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
}

.warning-text {
  font-size: 0.89em;
  color: orange;
}

.text-bold {
  font-weight: bold;
}

.break-text {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
  /* Instead use this non-standard one: */
      word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
     -moz-hyphens: auto;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.styleless-link {
  text-decoration: none;
  color: inherit;
}

.relative {
  position: relative;
}

.validated-field {
  position: relative;
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

#app:empty {
  position: relative;
  width: 100%;
  height: 100vh;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    border: 2px solid #2f9dc6;
    border-top-color: white;
    transform: translate(-50%, -50%);
    animation: rotate infinite linear 2s;
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.nowrap {
  white-space: nowrap;
}

.input--error {
  border-color: #ffbfbf !important;
}

.input--error .Select-control {
  border-color: #ffbfbf;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.generic-button {
  border-bottom: none;
  border-right: none;
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  min-width: 120px;
  text-transform: capitalize;
  position: relative;
  background-color: #2f9dc6;
  border-color: #2f9dc6;
  color: #ffffff;
  -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;

  &:hover {
    background-color: rgb(82.0530612245, 178.1346938776, 213.9469387755);
    border-color: rgb(82.0530612245, 178.1346938776, 213.9469387755);
  }

  &:focus,
  &:active {
    background-color: darken(#2f9dc6, 10%);
    border-color: darken(#2f9dc6, 10%);
  }
}

#printJS {
  display: none;
}

#intercom-container .intercom-app .intercom-launcher-frame {
  bottom: 60px !important;
}

#intercom-container .intercom-app-launcher-enabled div.intercom-messenger-frame {
  height: calc(100% - 55px - 75px - 20px) !important;
  bottom: calc(55px + 75px) !important;
}

.disabled-block {
  pointer-events: none;
  background: rgba(128, 128, 128, 0.25) !important;
}

.validation-error {
  position: absolute;
  top: 95%;
  left: 0;
  font-size: 12px;
  text-transform: initial;
  color: $formErrors;
  line-height: 12px;
}

.no-link {
  text-decoration: none;
  color: inherit;
  display: block;
  &:hover {
    background-color: transparent;
  }
}

.no-button {
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: inherit;
}

.selected-row {
  background-color: #d5ebf4!important;
} 

.button--submit {
  border-radius: 0;
  border: 0;
  color: #fff;
  display: block;
  padding: 6px 12px;
  background-color: #59b1d1;
  outline: none;
  text-decoration: none;
  font-size: .7em;
  cursor: pointer;
  margin-left: 10px;
}

.tabs-wrapper {
  flex: 1 1;
  min-height: 0;
  display: flex;
}

.child-tabs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.touch-none {
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}
