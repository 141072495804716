:local {
  .list {
    margin: 0;
    padding: 0 0 0 20px;

    & > li {
      padding-bottom: 5px;
    }
  }

  .no-list {
    composes: list;
    list-style: none;
  }

  .system-details {
    margin-top: 4px;
  }
}
