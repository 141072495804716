:local {
  .wrapper {
    overflow: auto;
    width: 50%;
  }

  .wrapper-inner {
    padding: 15px;
  }

  .button {
    margin: 0 10px;
    padding: 0.6em 1.2em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    -webkit-transition: all 0.3s;
            transition: all 0.3s;
    color: #aaaaaa;
    cursor: pointer;
    background: transparent;
    border: 1px solid #aaaaaa;
    min-width: 74px;

    &:hover {
      border-color: #2f9dc6;
      color: #ffffff;
      background-color: #2f9dc6;
    }

    &:disabled {
      background: #aaaaaa;
      cursor: not-allowed;
      color: white;
      &:hover {
        border-color: #aaaaaa;
      }
    }
  }

  .checkbox-wrapper {
    display: table;
    margin: 0 auto;
  }

  .modal-items-list {
    text-align: left;
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
  }

  .list-item {
    margin-bottom: 10px;
    padding: 5px 10px;
    background-color: #e8f0f3;
  }

  .medication-name {
    font-size: 14px;
    line-height: 1.3;
  }

  .checkbox-wrapper {
    padding: 5px 0;
  }

  .table-head {
    display: table;
    width: 100%;
  }

  .table-body {
    display: block;
    max-height: 420px;
    overflow: auto;
    width: 100%;
  }

  .table-row {
    display:table;
  	width:100%;
  	table-layout:fixed;
  }
}
