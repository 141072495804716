@use "src/styles/colors.scss" as *;

:local {
  .edit-card {
    width: 30px;
    height: 30px;
    background-image: url("../../images/edit.svg");
    background-size: cover;
    border: none;
    background-color: transparent;
  }

  .modal-window {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;

    &-keyWord-wrapper {
      display: flex;
      flex-direction: column;

      input {
        margin: 0 auto;
        border: 1px solid #b3b3b3;
      }
    }

    &-container {
      width: 100%;
      height: 100%;
    }

    &-overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }

    &-content {
      width: 30%;
      min-width: 300px;
      position: absolute;
      left: 50%;
      top: 50%;
      background: white;
      transform: translate(-50%, -50%);
    }

    &-content--large {
      width: 60%;
      max-width: 650px;
      position: absolute;
      left: 50%;
      top: 50%;
      background: white;
      transform: translate(-50%, -50%);
    }

    &-content--medium {
      width: 40%;
      min-width: 400px;
      position: absolute;
      left: 50%;
      top: 50%;
      background: white;
      transform: translate(-50%, -50%);
    }

    &--active {
      z-index: 999;
      opacity: 1;
      visibility: visible;
    }

    &-item {
      text-align: center;
    }

    &-controls {
      padding: 15px 0 20px;
      font-size: 0.85em;
    }

    &-title {
      font-size: 1.2em;
      padding: 14px 24px;
      color: #3c8cbb;
      border-bottom: 4px solid #3c8cbb;
      position: relative;
    }

    &-logo {
      position: absolute;
      left: 10px;
      top: 20%;
    }

    &-data {
      padding: 10px 20px;
    }
  }

  .button {
    margin: 0 10px;
    padding: 0.6em 1.2em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #aaaaaa;
    cursor: pointer;
    background: transparent;
    border: 1px solid #aaaaaa;

    &:hover {
      border-color: $basicEhrBlue;
      color: #ffffff;
      background-color: $basicEhrBlue;
    }

    &:disabled {
      background: #aaaaaa;
      cursor: not-allowed;
      color: white;
      &:hover {
        border-color: #aaaaaa;
      }
    }
  }

  .text-confirm-input-wrapper {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .text-confirm-input {
    padding: 2px 10px;
    border: 1px solid #b3b3b3;
    box-sizing: border-box;
    text-align: center;
    margin-top: 5px;

    &--error {
      composes: text-confirm-input;

      border-color: #ffbfbf;
    }
  }

  .error-text {
    color: #ffbfbf;
    font-size: 12px;
    font-weight: bold;
    padding-top: 2px;
    display: block;
  }

  .clinics-list {
    text-align: left;
  }

  .clinic-item {
    padding: 5px;
    background-color: #ccc;
    cursor: pointer;
    margin-bottom: 3px;

    &--active {
      composes: clinic-item;
      color: white;
      background-color: $basicEhrBlue;
    }
  }

  .keyword-label {
    margin-bottom: 10px;
  }

  .content-container {
    text-align: center;
    max-width: 440px;
    margin: 0 auto;
    padding-bottom: 5px;
  }
}
