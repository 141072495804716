:local {
  .wrapper {
    text-align: left;

    @media screen and (min-width: 768px) {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 145px);
    }
  }

  .main-info {
    margin: 15px 0 25px;
  }

  .secondary-info {
    color: #575757;
    font-size: 18px;

    p {
      margin: 5px 0;
    }
  }

  .name {
    margin: 0;
    font-size: 24px;
  }

  .id {
    margin: 5px 0;
    text-transform: uppercase;
    font-size: 15px;
  }

  .dob {
    margin: 5px 0;
    text-transform: uppercase;
    font-size: 15px;
    color: #2f9dc6;
  }

  .adress {
  }

  .phone {
  }

  .email {
    font-size: 15px;
    color: #2f9dc6;

    &-link {
      color: inherit;
    }
  }
}
