:local {
  .button {
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
