:local {
  .tab-content {
    background: #ffffff;
    display: flex;
    flex: 1;

    // flex-shrink not working in firefox without min-height: 0
    min-height: 0;
  }
}
