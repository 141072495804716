@use "src/styles/colors.scss" as *;
@use 'src/styles/input-variables.module.scss' as *;

:local {
  .calendar-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: $heightElement;
    cursor: pointer;
    background: $basicEhrBlue;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      background: url('./calendar-icon.png') 50% 50% no-repeat;
      background-size: contain;
      transform: translate(-50%, -50%);
    }
  }
}
