:local {
  .name {
    display: block;
    text-align: center;
  }

  .item {
    cursor: pointer;
    &--active {
      background-color: #d5ebf4 !important;
    }
  }
}
