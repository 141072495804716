@use "src/styles/basic" as *;
@use 'src/styles/colors' as *;

:local {
  nav {
    position: fixed;
    bottom: 1vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: gray;
    border-radius: 5px;
    z-index: 1;
    color: white;
  }
  .page-num {
    line-height: 24px;
  }
  .nav-button {
    composes: no-button;

    display: flex;
    width: 22px;
  }
  .pdf-viewer {
    position: relative;
    composes: touch-none;
  }
  canvas {
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    outline: none;
  }
  .document-container {
    position: relative;
    height: 100%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  .page-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  .actions {
    background-color: white;
    margin-bottom: 3px;
    column-gap: 20px;
    display: flex;
    padding: 5px;
    justify-content: space-between;

    .right-bar,
    .left-bar {
      display: flex;
    }

    button {
      padding-inline: 3px;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      * {
        pointer-events: none;
      }
    }

    .action__active {
      background: $lightBasic;
    }
  }
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}