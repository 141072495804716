:local {
  .wrapper {
    width: 100%;
    font-size: 15px;
    display: table;
  }

  .wrapper + .wrapper {
    margin-top: 20px;
  }

  .title {
    width: 160px;
    min-width: 160px;
    margin-right: 10px;
    // text-transform: uppercase;
    font-family: Open Sans;
    font-weight: 600;
    color: #2f9dc6;
    display: table-cell;
    padding-right: 10px;

    & > * {
      text-align: left;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .children {
    display: table-cell;
    vertical-align: top;

    button {
      text-align: left;
    }
  }
}
