$authFormBorder: 2px solid black;

:local {
  .auth-form {
    display: flex;
    align-items: stretch;
    width: 100vw;
    max-width: 100%;
    flex-wrap: wrap;
    position: relative;
    z-index: 4;
    height: 100%;
    border-top: $authFormBorder;
    border-bottom: $authFormBorder;

    /* родитель этого элемента будет иметь min-height либо 100vh если мы на десктопе,
     либо вычисленную высоту если мы на ipad
     лучше не трогать */
    min-height: inherit;
  }
}
