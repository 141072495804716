@use "src/styles/colors.scss" as *;

:local {
  @keyframes rotate {
    from {
      transform: translate(0, 0) rotate(0);
    }

    to {
      transform: translate(0, 0) rotate(360deg);
    }
  }

  .button {
    background: none;
    display: inline-block;
    border: none;
    padding: 0;
    color: $basicEhrBlue;
    transition: color 0.2s;
    position: relative;

    .loader {
      position: static;
      width: 30px;
      height: 30px;
      transform: translate(0,0);
      left: 0;
      top: 0;
      animation: rotate infinite linear 2s;
    }

    // BUG: display: flex doesn't work on buttons in firefox
    & > span {
      display: flex;
      align-items: center;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .text {
    margin-left: 10px;
  }

  .icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $basicEhrBlue;
    cursor: pointer;
    transition: border-color 0.2s;


    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 66%;
      height: 2px;
      background: $basicEhrBlue;
      transform: translate(-50%, -50%);
      transition: background 0.2s;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
