@use "src/styles/loader" as *;

:local {
  .wrapper {
    position: absolute;
    top: 34px;
    left: 0;
    bottom: 40px;
    width: 100%;

    table {
      margin: 0;
      height: 100%;
    }
  }

  .fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #2f9dc6;
    height: 55px;

    & > * {
      float: left;
      text-align: center;
      padding: 8px;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
    }
    &:after {
      clear: both;
    }
  }

  .title {
    text-align: center;
    display: block;
  }

  .subtitle {
    margin-top: 5px;
    text-transform: none;
    text-align: center;
    display: block;
    font-weight: 300;
    font-size: 11px;
    color: #baecff;
  }

  .load {
    composes: loader;
    position: relative;
    height: 400px;
    background: #f0f0f0;
  }
}
