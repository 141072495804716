:local {
  .wrapper {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    text-align: center;
  }

  .logo {
    text-transform: uppercase;
    font-weight: 600;
    color: #267e9e;
    font-size: 1.2em;
    margin: 10px 0;
    span {
      font-size: .6em;
    }
  }

  .version {
    font-size: .8em;
  }

  .root-link {
    text-decoration: none;
  }
}
