@use "src/styles/colors.scss" as *;

:local {
  .clinic-list {
    width: 24%;
    margin-left: 0;
    background-color: #ececec;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    transition: margin-left .3s ease;

    @media screen and (max-width: 999px) {
      width: auto;
      margin: 0;
      position: fixed;
      top: 40px;
      left: 40px;
      right: 40px;
      bottom: 40px;
      opacity: 0;
      transform: scale3d(.9, .9, .9);
      transition: transform .3s ease, opacity .3s ease;
    }
  }

  .clinic-list--visible {
    margin-left: 24%;

    @media screen and (max-width: 999px) {
      transform: scale3d(1, 1, 1);
      opacity: 1;
      z-index: 10;
      margin: auto;
    }
  }

  .clinic-list__no-sidebar {
    top: -9999px;
    left: -9999px;
    right: -9999px;
    bottom: -9999px;
    height: 90vh;
    width: 100vw;
    z-index: 10;
    margin: auto;
    max-width: 476px;

    @media screen and (max-width: 999px) {
      top: -9999px;
      left: -9999px;
      right: -9999px;
      bottom: -9999px;
      height: 90vh;
      width: 100vw;
      margin: auto;
    }
  }
}
