:local {
  .wrapper {
    height: 100%;
    position: relative;
  }

  .section-wrapper {
    height: 50%;
    overflow: hidden;
    display: flex;
  }

  .dashboard-section {
    height: 100%;
  }

  .select-wrapper {
    position: absolute !important;
    top: 5px;
    right: 0;
  }

}
