:local {
  .table {
    width: 150px;
    border: 1px solid #b3b3b3;
    border-left: none;
  }

  .bmi-icon {
    margin-left: 10px;
    vertical-align: middle;
  }

  .bmi > * {
    vertical-align: middle;
  }
}
