@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    position: relative;
    min-height: inherit;
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 46px 25px;
    flex: 1 0 auto;

    input,
    textarea {
      border-radius: 0;
      -webkit-appearance: none;
    }
    &-responsive-height {
      composes: wrapper;

      height: 100%;
    }
  }

}
