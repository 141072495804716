@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    cursor: pointer;

    &--active {
      .burger {
        background: transparent;
        &:before {
          top: 0;
          transform: rotate(45deg);
        }
        &:after {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .burger {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    margin-top: -1px;
    background: rgba(0,0,0,0.7);

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(0,0,0,0.7);
    }

    &:before {
      top: -7px;
    }

    &:after {
      top: 7px;
    }
  }

  @media screen and (min-width: 1000px) {
    .wrapper {
      display: none;
    }
  }
}
