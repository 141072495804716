@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid $basicEhrBlue;
    background: none;
    transition: background-color 0.2s ease-in;

    &:focus,
    &:active {
      outline: none;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
      background: $basicEhrBlue;
    }

    &:before {
      width: 54%;
      height: 2px;
    }

    &:after {
      width: 2px;
      height: 54%;
    }

    span {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      font-weight: 700;
      font-size: 0.6em;
      text-shadow: 1px 1px 0 #ffffff, -1px -1px 0 #ffffff;
      -webkit-transform: translate(50%);
              transform: translate(50%);
      color: #c62f2f;
      text-transform: uppercase;
    }

    &--neg {
      &:after {
        display: none;
      }

      span {
        color: $basicEhrBlue;
      }
    }
  }
}
