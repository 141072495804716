@use "src/styles/basic" as *;

:local {
  .toolbar{
    position: relative;
    display: flex;
    column-gap: 15px;
  }
  .container {
    background-color: #00000082;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    composes: touch-none;
  }
}