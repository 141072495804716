:local {
  .wrapper {
    width: 100%;
    font-size: 14px;
    border-right: 1px solid #b3b3b3;
    box-sizing: content-box;
  }

  .label {
    text-align: left;
  }

  .table {
    width: 100%;
  }

  .measure {
    color: #2f9dc6;
  }

  .input {
    width: 80px;
    height: 30px;
    border-radius: 0;
    -webkit-appearance: none;
    border: 1px solid #b3b3b3;
    text-align: center;
  }

  .bmi-icon {
    margin-left: 10px;
  }

  .bmi > * {
    vertical-align: middle;
  }
}
