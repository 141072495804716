:local {
  .edit-form {
    /*display: table-cell;*/

    vertical-align: top;
    width: 45%;
    margin-top: 0;
    /*padding: 15px;*/
    background: #e8f0f3;
    box-sizing: border-box;
    /*height: 100%;*/
    overflow: auto;
    overflow: overlay;
    // padding-right: 17px;
    position: relative;
    -webkit-overflow-scrolling: auto;
    display: flex;

    &-wrapper {
      // ie11 fix for social tab
      position: relative;
      min-height: 100%;
      display: flex;
      flex: 1 1 auto;

      /*height: 100%;*/
    }
  }

  /*@media screen and (min-width: 992px) {
    .edit-form {
      display: table-cell;
      vertical-align: top;
      width: 45%;
      margin-top: 0;
      overflow: auto;
      -webkit-overflow-scrolling: auto;
    }
  }*/
}
