:local {
  .wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
    &:first-child {
      padding-top: 0;
    }
  }

  .system-name {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    text-transform: uppercase;
  }

  .system-name-active {
    display: inherit;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
  }

  .name {
    padding-right: 7px;
  }

  .name-active {
    font-weight: 900;
  }

  .elements-controls {
    margin-top: 10px;
  }

  .remove-button-active {
    float: right;
  }

  .remove-button {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #cccccc;
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-top: 1px;
    cursor: pointer;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    box-sizing: border-box;
    -webkit-transition: border-color 0.2s;
            transition: border-color 0.2s;

    &:hover {
      border-color: #8a8a8a;
    }

    &:hover:before,
    &:hover:after {
      background: #8a8a8a;
    }

    &:after,
    &:before {
      content: '';
      background: #cccccc;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
      -webkit-transition: background 0.2s;
              transition: background 0.2s;
    }

    &:before {
      width: 80%;
      height: 1px;
    }

    &:after {
      width: 1px;
      height: 80%;
    }
  }
}
