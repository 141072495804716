:local {
  .wrapper {
    &:hover {
      background: #d5ebf4 !important;
    }
  }

  .show-more-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .full-message {
    white-space: normal;
  }

  .short-message {
    padding-right: 25px;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

:global {
  .log-message-element {
    z-index: 1;
    text-align: center;
    background: white;
    padding: 8px;
    border: 1px solid #c9c9c9;
    max-width: 300px;
  }
}
