@use "src/styles/colors.scss" as *;

@use 'src/styles/input-variables.module.scss' as *;
@use 'src/styles/inputs.module.scss';
@use 'src/containers/Demographics/DemographicsForm/style.module.scss';
@use 'src/styles/loader.module.scss';


:local {
  .wrapper {
    margin: 50px 0 0 50px;
    display: table;
    position: relative;
  }

  .box-logo {
    max-width: 135px;
  }

  .help-icon-container {
    position: absolute;
    top: 0;
    left: -30px;
  }

  .element-wrapper {
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
    margin-top: 52px;
    margin-left: 40px;

    & > button {
      background-color: white;
    }

    :global {
      .wrapper {
        display: inline-block;
        margin-left: 20px;
      }
    }
  }
}
