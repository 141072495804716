@use  "src/styles/loader.scss" as *;

:local {
  .wrapper {
    position: relative;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;

    // flex-shrink not working in firefox without min-height: 0
    min-height: 0;
    height: 100%;
    padding: 5px 5px 0;
    &--loading {
      composes: loader;
    }
  }

  .container {
    composes: wrapper;
    padding: 0;
  }

  @media screen and (min-width: 1025px) {
    .wrapper {
      padding: 15px 15px 0;
    }
  }
}
