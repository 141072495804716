:local {
  .wrapper {
    padding: 3px 3px 3px 68px;
    position: relative;
    background-color: #fff;
    cursor: pointer;
  }

  .wrapper__no-image {
    padding: 3px 3px 3px 5px;
    height: 66px;
    position: relative;
    background-color: #fff;
    cursor: pointer;
  }

  .no-image {
    padding-left: 5px;

    input {
      width: 80%;
    }
  }

  .input-wrapper {
    composes: wrapper;
  }

  .input-wrapper__no-image {
    composes: wrapper__no-image;
  }

  .item-wrapper__no-image {
    composes: wrapper__no-image;
  }

  .item-wrapper {
    composes: wrapper;

    z-index: 10;
  }

  .image-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;

    img {
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .signature {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .name {
    color: #575757;
    font-weight: 300;
    font-size: 14px;
    padding: 10px 0 5px 0;
    border-bottom: 1px solid #b3b3b3;
    text-align: left;
    line-height: 14px;
  }

  .email {
    color: #2b657a;
    padding: 3px 0;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    line-height: 14px;
  }

  .email-input {
    composes: email;

    border: none;
    display: block;
    outline: none;
    border-bottom: 1px solid #b3b3b3;
    margin-top: 17px;
    position: relative;
    width: 80%;
    // z-index: 1;
  }
}
