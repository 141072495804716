:local {
  .wrapper {
    text-align: justify;
    font-size: 0;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }
  .no-delete-button {
    text-align: right;
    &:after {
      display: none;
    }
  }

  .button {
    padding: 7px 30px;
    font-size: 1rem;
    height: 34px;

    &-delete {
      display: inline-block;
      vertical-align: middle;
      height: 34px;
      width: 34px;
      border: 1px solid #e13046;
      border-radius: 50%;
      background: url('./trashbin.png') center center no-repeat;
      background-size: 16px 20px;
      cursor: pointer;
      outline: none;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f8ccd1;
      }

      &[disabled] {
        opacity: 0.3;
      }

      @media screen and (min-width: 920px) {
        height: 40px;
        width: 40px;
        background-size: 18px 22px;
      }
    }

    &-cancel {
      border-radius: 48px;
      border: 1px solid #60b3d2;
      background-color: transparent;
      color: #60b3d2;
      text-align: center;
      cursor: pointer;
      margin-left: 10px;
      outline: none;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #cbe7f2;
      }

      &[disabled] {
        opacity: 0.3;
      }

      &:not(:disabled):hover {
        background-color: #2f9dc6;
        color: white;
      }
    }

    &-submit {
      composes: button-cancel;
      background-color: #cbe7f2;
      transition: all 0.3s;

      &[disabled] {
        opacity: 0.3;
      }

      &:not(:disabled):hover {
        background-color: #2f9dc6;
        color: white;
      }
    }

    @media screen and (min-width: 920px) {
      padding: 7px 40px;
      font-size: 1.4rem;
      height: 40px;
    }
  }


  .group {
    display: flex;
    vertical-align: middle;
  }

  @media screen and (min-width: 1025px) {
    &-cancel {
      margin-left: 20px;
    }
  }
}
