@use '../../../../../../../../../styles/input-variables.module' as *;
@use '../../../../../../../../../styles/inputs.module';
@use '../../../../../../../../../containers/Demographics/DemographicsForm/style.module';
@use '../../../../../../../../../styles/loader.module';

:local {
  .element-group:nth-child(2) {
    position: static;
    display: flex;
    flex-direction: column;
  }

  .image-loader-group {
    composes: element-group;
  }

  .form {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
    background-color: #ffffff;
  }

  .label-required {
    composes: label;

    &:after {
      content: '*';
    }
  }

  .npi-wrapper {
    composes: small-wrapper;
    float: right;
    clear: none !important;
  }

  .tax-wrapper {
    composes: small-wrapper;
    float: left;
    clear: none !important;
    //margin-bottom: 16px;
  }

  .phone-wrapper {
    width: auto;
  }

  .phone-input-container {
    width: 210px;
    float: left;
  }

  .phone-number-type {
    width: 210px;
    float: left;
    margin-left: 20px;
    height: 28px;
  }
}
