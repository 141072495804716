@use 'input-variables.module.scss' as *;

.element-group {
  clear: both;
  margin-top: $marginGroupTop;
  margin-right: $marginMainRight;

  .element-wrapper {
    margin-bottom: $marginGroupTop;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.element-wrapper {
  position: relative;
  margin-left: $marginMainLeft;
}

.label {
  position: absolute;
  top: 50%;
  right: 100%;
  padding-right: 20px;
  font-size: $fontSize;
  text-transform: uppercase;
  cursor: pointer;
  white-space: pre;
  transform: translateY(-50%);
}

.input {
  height: $heightElement;
  width: 100%;
  padding: 2px 10px;
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
  font-size: $fontSize;


  &.error {
    border-color: $errorColor;
  }
}

.radio-wrapper {
  font-size: 0;
  position: relative;

  input[type=radio] {
    display: none;

    &:checked + label {
      &:before {
        background-color: $colorBlue;
        border-color: $colorBlue;
      }
    }

    & + label {
      cursor: pointer;
      position: relative;
      content: '';
      display: inline-block;
      width: $heightRadioButton;
      height: $heightRadioButton;
      background-color: $colorWhite;
      border-radius: 50%;
      border: 1px $borderColor solid;

      &:before {
        content: '';
        display: inline-block;
        width: $heightSmallCircle;
        height: $heightSmallCircle;
        border-radius: 50%;
        left: $heightSmallCircle / 2;
        top: $heightSmallCircle / 2;
        position: absolute;
        border: 1px $borderColor solid;
      }
    }
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  min-width: 120px;
  background: transparent;
  color: #404040;
  border: 1px solid #c2c2c2;
  transition: all 0.3s;
  text-transform: capitalize;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $colorBlue;
    border-color: $colorBlue;
    color: white;
  }
}

.save-btn {
  composes: button;
  position: relative;
  background-color: $colorBlue;
  border-color: $colorBlue;
  color: white;
  transition: opacity 0.3s;

  &[disabled] {
    opacity: 0.3;
  }
}

.small-wrapper {
  composes: element-wrapper;
  width: $widthSmallInput;

  &:last-child {
    margin-bottom: 0;
    clear: both;
  }
}
