@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
     -moz-hyphens: auto;
  -webkit-hyphens: auto;
          hyphens: auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin min-height($height) {
  @media screen and (min-height: $height) {
    @content;
  }
}

@mixin min-width($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin progress-bar-text() {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #828282;
  transform: translate(-50%, -50%);
}
