:local {
  .wrapper {
    margin-top: 10px;
    overflow: hidden;
    min-height: 36px;
  }

  .link {
    border: 1px solid #2f9dc6;
    color: #2f9dc6;
    background: none;
    border-radius: 50px;
    font-size: 18px;
    padding: 5px 10px;
    width: 100%;
    white-space: nowrap;
    display: block;
    text-decoration: none;

    &:active,
    &:focus {
      outline: none;
    }

    &--active {
      background: #cbe7f2;
    }

    &-container {
      width: 50%;
      float: left;
      text-align: center;
      padding-right: 10px;
      padding-left: 0;
    }
  }

  .link-container + .link-container {
    padding-left: 10px;
    padding-right: 0;
  }
}
