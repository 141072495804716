@import "src/styles/basic";

:local {
  $labelPadding: 15px;
  $labelColor: white;
  $labelFontWeight: normal;

  .label {
    color: white;
    margin-top: 0;
    font-size: 19px;
    margin-bottom: 8px;
    composes: no-button;
    padding-left: 15px;
    font-weight: normal;
  }

  @mixin contactLink() {
    padding-left: $labelPadding;
    color: $labelColor;
    font-weight: $labelFontWeight;
  }

  .contacts--background {
    background: linear-gradient(252.46deg, #439BB5 3.38%, rgba(67, 155, 181, 0) 95.81%);
    min-width: 100%;
  }

  .contacts {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;

    &__header-label {
      font-size: 1.5em;
    }

    &__containers {
      display: flex;
      flex-direction: column;
    }

    &__containers-item {
      align-self: flex-start;
      display: flex;
      align-items: center;
      width: 210px;

      & > span {
        @include contactLink();
      }
    }

    &__containers-item:first-child {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    &__mail-wrapper {
      & > a {
        text-decoration: none;
        @include contactLink();
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .contacts {
      align-items: center;
    }

    .contacts__containers-item {
      align-self: center;
    }
  }

  .link-with-children {
    padding-left: 0;
    composes: no-button;
  }
}
