:local {
  .patient-data {
    /*display: table-cell;*/
    vertical-align: top;
    width: 55%;
    // padding-right: 15px;
    margin-right: 15px;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
    overflow-x: hidden;
  }
}
