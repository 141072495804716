:local {
  .name {
    display: block;
    text-align: center;
  }

  .comment {
    display: block;
    text-align: center;
  }

  .container {
    font-size: 12px;
    tbody {
      tr.item {
        cursor: pointer;
        &--active {
          background-color: #d5ebf4;
        }
      }
    }
  }

  .no-known-drug-allergies {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
