:local {
  .wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    h3 {
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
      font-size: 20px;
    }
  }
}

:global {
  .dialog-actions {
    text-align: right;
    margin-top: 20px;
  }
}
