@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #b3b3b3;
    border: none;
    background: none;

    &:active,
    &:focus {
      outline: none;
    }

    &--active {
      color: #30c6a1;
      .checkbox {
        background-position: -23px 0;
      }
    }
  }

  .checkbox {
    cursor: pointer;
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: 44px 22px;
    background-image: url('../../images/checkbox-sprite.png');
    margin-right: 5px;
    background-repeat: no-repeat;
  }
}
