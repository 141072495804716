:local {
  .form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .permissions-container {
    flex-grow: 1;
    overflow: auto;
    margin-bottom: 13px;
  }

  .row {
    margin: 13px 0;
  }

  .form-body {
    display: flex;
    flex-direction: column;
  }
}
