@use '../../../../../../../../../../styles/input-variables.module' as *;
@use '../../../../../../../../../../styles/loader.module';
@use '../../../../../../../../../../styles/basic';

:local {
  .tab-content {
    background: #ffffff;
    min-height: 0;
  }

  .button {
    font-size: 12.5px;
    padding: 4px 17px;

    &-wrapper {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    &-view {
      border-radius: 48px;
      border: 1px solid #60b3d2;
      background-color: transparent;
      color: #60b3d2;
      text-align: center;
      cursor: pointer;
      outline: none;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #cbe7f2;
      }

      &[disabled] {
        opacity: 0.3;
      }

      &:not(:disabled):hover {
        background-color: #2f9dc6;
        color: white;
      }
    }

    &-plan {
      composes: button-view;
      background-color: #cbe7f2;
      transition: all 0.3s;
      margin-left: 10px;
      &[disabled] {
        opacity: 0.3;
      }

      &:not(:disabled):hover {
        background-color: #2f9dc6;
        color: white;
      }
    }
  }
}
