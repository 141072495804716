@use "src/styles/colors.scss" as *;

:local {
  .document {
    padding-top: 48px;
    box-sizing: border-box;
    height: 100%;
  }

  @media screen and (min-height: 768px) {
    .document {
      height: calc(100%);
    }
  }

  .document-wrapper {
    overflow: auto;
    height: 100%;
  }

  .document-container {
    height: 100%;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 900px;
  }

  .header {
    height: 48px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 2px 3px rgba(1, 1, 1, 0.3);
    background-color: $basicEhrBlue;
  }

  .back-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 14px;
    // color: #267e9e;
    color: #fff;
    padding: 6px 10px 6px 24px;
    // border: 1px solid #267e9e;
    cursor: pointer;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      // transform: translateY(-50%) scaleY(0.5) scaleX(0.7);
      transform: translateY(-50%);
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAYCAYAAAAh8HdUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMRJREFUeNpi+P//PwMBbAvE3/5DwFcgtiKkgR2Ir/9HgASQOCFNk5E0bIGJ49OQjaThNhBLENLkCMS/oBo+ALERsjw2DRxAfB7Jllh0Ndg0zUXSsBmbS9AFSpE0XAFiIUKabID4L1TDayDWwhVIMAYPEJ9BsiUJX1TAGMuRNKwCYiZCmtqQNBwDYk5CSYuJgRxArvPIDgiygpzsyCU7GcES7AVSEyxZWQOGc0jNhDA8hdTsDitYbpBasMCKsO/IRRhAgAEAh6TJmT6Nt48AAAAASUVORK5CYII=);
      background-position: 0 0;
      background-repeat: no-repeat;
      width: 13px;
      height: 24px;
    }
  }
}
