@use 'src/styles/triangle' as *;

:local {
  .popup-wrapper {
    padding-bottom: 10px;
  }

  .popup {
    position: relative;
    white-space: nowrap;
    padding: 10px;
    border-radius: 40px;
    border: 1px solid #b3b3b3;
    background: #ffffff;
    min-width: 60px;
    text-align: center;
    visibility: visible;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
    }

    &:before {
      @include triangle(bottom, 10px, 10px, #b3b3b3);
    }

    &:after {
      @include triangle(bottom, 10px, 10px, white);
      margin-top: -1px;
    }
  }
}

:global {
  .reportElement-element.reportElement-out-of-bounds-top,
  .reportElement-element.reportElement-out-of-bounds-bottom {
    // display: none doesn't work!!!
    position: fixed !important;
    top: -9000px !important;
    left: -9000px !important;
  }

  .reportElement {
    &-element {
      z-index: 6;

      &-attached-top {
        :local {
          .popup-wrapper {
            padding-bottom: 0;
            padding-top: 10px;
          }

          .popup {
            &:before,
            &:after {
              top: auto;
              bottom: 100%;
            }

            &:before {
              @include triangle(top, 10px, 10px, #b3b3b3);
            }

            &:after {
              @include triangle(top, 10px, 10px, white);
              margin-top: 0;
              margin-bottom: -1px;
            }
          }
        }
      }
    }
  }
}
