:local {
  .wrapper {
    flex: 1 1 0;
    display: flex;
    position: relative;
    font-size: 12px;
    flex-direction: column;

    // flex-shrink not working in firefox without min-height: 0
    min-height: 0;

    //ie11 needs this to shrink properly
    // height: 100%;

    table {
      margin: 0;
    }
  }

  .loader-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: 0.6;
    z-index: 1;
  }

  .loader {
    z-index: 1;
  }
}
