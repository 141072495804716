:local {
  .table {
    width: 100%;
    text-align: center;
    font-size: 13px;
    border: 1px solid #b3b3b3;

    td {
      position: relative;

      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      white-space: pre-wrap;
          word-break: break-all;
      /* Instead use this non-standard one: */
          word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
         -moz-hyphens: auto;
      -webkit-hyphens: auto;
              hyphens: auto;
    }

    td,
    th {
      padding: 10px 10px;

      &:last-child {
        min-width: 150px;
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background: rgb(213.9453061224, 236.7534693878, 245.2546938776);
        }
      }
    }
  }

  .table + .table {
    margin-top: 15px;
  }

  .header {
    background: #2f9dc6;
    color: white;
    text-transform: uppercase;
    th {
      font-weight: normal;
    }
  }

  @media screen and (min-width: 1025px) {
    .table {
      font-size: 15px;
    }
  }
}
