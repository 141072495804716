:local {
  .page-container {
    box-sizing: border-box;
    overflow: hidden;
    color: #333;
    padding: 30px 0;
  }

  .paragraph {
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 1.3;
    margin: 0;
  }

  .page-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .link {
    color: #267e9e;
  }

  .date {
    font-weight: bold;
    font-size: 16px;
  }

  .header {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .sub-header {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .underlined-header {
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
    text-decoration: underline;
  }

  .underlined {
    text-decoration: underline;
  }

  .list {
    margin: 10px 0;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #267e9e;
        border-radius: 50%;
        width: 6px;
        height: 6px;
      }
    }
  }
}
