:local {
  .seat-container {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: center;
    margin-bottom: 5px;
    text-align: left;
  }

  .seat-container__color {
    height: 19px;
    border-radius: 50px;
    min-width: 19px;
  }

  .seat-container__description {
    padding-left: 10px;
  }
}