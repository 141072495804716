@use 'src/styles/loader.module.scss';

:local {
  $width: 11px;
  $height: 8px;
  $inputHeight: 30px;

  .wrapper {
    // 2px for borders
    height: $inputHeight + 2px;
    position: relative;
    cursor: pointer;
    width: 100%;

    :global {
      .Select-input {
        height: $inputHeight;
      }

      .Select-placeholder,
      .Select--single > .Select-control .Select-value {
        line-height: $inputHeight;
      }

      .Select-control {
        height: $inputHeight;
      }

      .Select-input > input {
        padding: 8px 0;
      }

      .Select-arrow {
        background: none;
        width: 0;
        height: 0;
        border-left: $width/2 solid transparent;
        border-right: $width/2 solid transparent;
        border-top: $height solid white;
      }
    }
  }

  .overlay {
    position: absolute;
    cursor: default;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 4;
  }
}
