@use '../../../../../../../../styles/triangle' as *;

:local {
  .wrapper {
    padding-top: 10px;

    &--standalone {
      padding-top: 0;
      margin: 0 auto;
      width: 100%;
      .inner-wrapper {
        width: 100%;
        &:before {
          display: none;
        }
      }

      .loader-wrapper {
        background: #f0f0f0;
        border: none;
      }
    }
  }

  .inner-wrapper {
    display: table;
    position: relative;
    max-width: 520px;
    min-width: 510px;

    &:before {
      position: absolute;
      bottom: 72%;
      left: 100%;
      @include triangle(right, 10px, 10px, #828282);
    }

    &--loading {
      &:before {
        @include triangle(right, 10px, 10px, #828282);
      }
    }
  }

  .form,
  .prev-vitals {
    display: table-cell;
    vertical-align: top;
  }

  .prev-vitals-container {
    display: flex;
    position: relative;
    max-width: 150px;
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    white-space: nowrap;
  }

  .line {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #b3b3b3;
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    height: 420px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    border: 1px solid #b3b3b3;
  }
}
