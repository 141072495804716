@use "src/styles/colors.scss" as *;

:local {
  .edit-mode-action--icon {
    margin: 0 5px;
    width: 40px;
    border: 1px solid $basicEhrBlue;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    height: 40px;
  }

  .edit-mode-action--green-selector {
    border: 2px solid #30c6a1;
  }

  .remove-element-button {
    background: url(../../images/trashbinBlue.png) center center no-repeat;
    background-size: 60%;
  }

  .reset-favorites-element-button {
    background: url(../../images/remove.png) center center no-repeat;
    background-size: 60%;
  }

  .merge-element-button {
    background: url(../../images/merge.png) center center no-repeat;
    background-size: 60%;
  }

  .elementsViewItemActionsIcons {

    z-index: 7;
    text-align: center;
    background: white;
    padding: 8px 20px;
    border-radius: 8px;
    border: 1px solid #c9c9c9;
    margin-left: -135px;
    min-width: 200px;
    position: absolute;
    top: -15px;
    left: -80px;

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      bottom: 30%;
      border: 10px solid transparent;
      border-left: 10px solid white;
    }
  }
}
