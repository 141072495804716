:local {
  $buttonWidth: 30px;
  $blockWidth: $buttonWidth + $buttonWidth + 5px;

  .wrapper {
    display: flex;
    position: relative;
  }

  .control-buttons {
    position: absolute;
    top: -43px;
    width: $buttonWidth + $buttonWidth + 5px;
    right: 0;
  }

  .cancel {
    margin-left: 5px;
  }

  .textarea {
    width: 100%;
    max-width: 100%;
    background: none;
    border: none;
    padding: 0;
    outline: 0;
    border-bottom: 1px solid rgb(51,51,51);
    resize: none;
  }
}


