:local {
  .report-header {
    font-size: 21px;
    color: #5f5f5f;
    font-weight: normal;
    position: relative;
    padding-bottom: 7px;
    // text-transform: uppercase;
  }

  .report-header-border {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #c7c7c7;
    background: #969696;
  }
}
