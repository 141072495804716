:local {
  $circleDiameter: 24px;
  $circleDiameterBig: 33px;
  $lineWidth: 2px;
  $lineWidthBig: 4px;
  $lineTopOffset: ($circleDiameter / 2px)px;
  $lineTopBigOffset: $circleDiameterBig / 2;
  $fontSize: 14px;
  $fontSizeBig: 16px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 116px;
    font-size: $fontSize;
    font-weight: bold;
    position: relative;
  }

  .checkmark {
    display: inline-block;
    width: 22px;
    height: 22px;
        -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
            transform: rotate(45deg);

    &:before {
      position: absolute;
      width: 3px;
      content: '';
      height: 18px;
      background-color: #cccccc;
      left: 11px;
      top: 1px;
      background: #267e9e;
    }
    &:after {
      position: absolute;
      width: 7px;
      content: '';
      height: 3px;
      background-color: #cccccc;
      left: 5px;
      top: 16px;
      background: #267e9e;
    }
  }

  .circle {
    width: $circleDiameter;
    height: $circleDiameter;
    border-radius: 50%;
    background: #2f9dc6;
    border: 2px solid #267e9e;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    justify-content: center;
    color: #89e0ff;



    &--active {
      background: #89e0ff;
      color: #267e9e;
    }

    &--passed {
      composes: circle--active;
      content: 'test';
    }
  }

  .line {
    position: absolute;
    width: 50%;
    z-index: 1;
    height: $lineWidth;
    background: #2f9dc6;
    border: 2px solid #267e9e;
    left: 25%;
    top: 50%;
    margin-top: -2px;
    &--active {
      background: #89e0ff;
    }
  }

  @media screen and (min-width: 1025px) {
    .wrapper {
      font-size: $fontSizeBig;
    }
    .circle {
      width: $circleDiameterBig;
      height: $circleDiameterBig;
    }
    .line {
      height: $lineWidthBig;
    }
  }
}
