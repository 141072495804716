@use "src/styles/colors.scss" as *;

:local {
  .back-button__wrapper {
    position: absolute;
    left: 70px;
    top: 15.5px;

    & > .back-btn {
      outline: none;
      color: #59b1d1;
      cursor: pointer;
      text-align: center;

        img {
          height: 18px;
        }
    }

    @media only screen and (min-width: 1370px) {
      .back-btn {
        display: none;
      }
    }
  }
}
