:local {
	.radio-button {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 24px;
		height: 24px;
		background: white;
		border: 1px solid #c5c5c5;
		border-radius: 50%;
		cursor: pointer;
		transition: all .3s;

		input {
			display: none;
		}

		&:before {
			content: '';
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    width: 10px;
	    height: 10px;
	    border: inherit;
	    border-radius: inherit;
	    box-sizing: border-box;
	    transform: translate(-50%, -50%);
	    transition: all .3s;
		}

		&-active {
			composes: radio-button;
			&:before {
				background-color: #2f9dc6;
				border-color: #2f9dc6;
			}
		}
	}

}
