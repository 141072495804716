:local {
  .table {
    text-align: center;
    background: white;
    border: 1px solid #b3b3b3;
    border-right: none;
    border-collapse: initial;
    vertical-align: top;

    td,
    th {
      white-space: nowrap;
      padding: 0 7px;
      height: 35px;
    }

    th {
      background: #828282;
      color: white;
      font-weight: normal;
    }

    tbody tr:nth-child(even) {
      background-color: #f5f5f5;
    }

    td:nth-of-type(2) {
      border-left: 1px solid #b3b3b3;
      background: #f0f0f0;
    }
  }
}
