@use "src/styles/colors.scss" as *;
@use 'src/styles/input-variables.module.scss' as *;
@use 'src/styles/inputs.module.scss' as *;

:local {
  $widthContainer: 120px;

  .wrapper {
    width: $widthContainer;
    clear: fix;

    .label {
      display: none;
    }
  }

  .demographics-wrapper {
    width: 360px;
    height: 120px;
    margin: 0 0 16px 180px;
    position: relative;
    clear: fix;

    .label {
      top: 0;
      transform: none;
    }

    .avatar {
      width: 100%;
      background-size: 100%;
      border: 1px solid #b3b3b3;
      transition: background-image 0.2s ease-in-out;
      border-radius: 0;
    }

    .controls {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }

    .info-popup-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 120px);
    }
  }

  .info-popup-container {
    display: table;
    position: absolute;
    right: 5px;
    bottom: 28px;
    z-index: 6;
  }

  .avatar {
    width: $widthContainer;
    height: $widthContainer;
    overflow: hidden;
    border-radius: 50%;
    position: relative;

    &-image {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .signature-image {
    composes: avatar-image;
    background-size: contain;
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#ffffff, 0.5);
    border-radius: 50%;
  }

  .controls {
    text-align: center;
    margin-top: 10px;

     > :first-child {
      display: none;
    }
  }

  .button {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    font-size: 10px;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      margin: auto auto 5px;
      border-radius: 50%;
      background-color: $basicEhrBlue;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  .file-button {
    composes: button;

    &:before {
      background-image: url(./images/file.png);
    }

    &:hover {
      background: transparent;
      color: #404040;
    }
  }

  .error {
    position: absolute;
    top: 100%;
    color: #ff0000;
    font-size: 12px;
    width: 120px;
    text-align: center;
  }
}
