@use "src/styles/colors.scss" as *;

:local {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .edit-button {
    margin-right: 0 !important;
    display: block;
  }

  .control-button {
    width: 35px !important;
    height: 35px !important;
    background-color: transparent !important;

    &-wrapper {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .save-button-wrapper {
    right: 40px;
  }


  .outer-wrapper {
    position: relative;
    padding-right: 41px;
    &--edit {
      padding-right: 78px;
    }

    .input {
      width: 64%;
    }
  }


  .wrapper {
    padding: 2px 0;
    font-size: 13px;
    text-transform: uppercase;

    &--hidden {
      display: none;
    }

    &:hover {
      .buttons-container {
        opacity: 1;
      }
    }
  }

  .checkbox-wrapper {
    padding: 10px 0;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      margin: 0 10px 0 5px;
    }
  }

  .item-name {
    $lines-limit: 2;
    $line-height: 1.2;
    cursor: pointer;
    max-width: calc(100% - 60px);
    line-height: $line-height;
    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: calc(13px * #{$line-height} * #{$lines-limit});
    text-align: left;

    &--ros {
      // ros has pos and neg button, 100px - total width
      width: calc(100% - 108px);
    }
  }

  .item-name--disabled {
    pointer-events: none;
  }

  .broadMatch {
    background: #fafad2 !important;
  }

  .exactMatch {
    background: #c3e8e3 !important;
  }

  .edit {
    background: none;
    border: none;
    display: table;
    border-bottom: 1px solid $basicEhrBlue;
    margin-top: 1px;
    vertical-align: middle;
    resize: none;
    overflow: overlay;
    line-height: 1.2;
    padding: 0;
    width: 70%;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .buttons-container {
    display: inline-block;
    opacity: 0;
    vertical-align: middle;
    height: 22px;
    width: 16px;
    position: relative;
    margin-left: 10px;
    top: -1px;
    transition: opacity 0.3s ease;
  }
}
