:local {
  $sidebarWidth: 80px;

  .wrapper {
    position: relative;
    will-change: transform;
    transition: transform 0.3s;
    height: 100%;
    box-sizing: border-box;

    @media screen and (min-width: 1000px) {
      z-index: 1;
      width: calc(100% - #{$sidebarWidth});
      margin-left: $sidebarWidth;
    }

    &--active {
      composes: wrapper;
      @media screen and (max-width: 1000px) {
        transform: translate($sidebarWidth, 0);
      }
    }
    .container {
      min-height: inherit;
      padding: 0 7px;
      height: 100%;
    }

    .container--wide {
      height: 100%;
      min-height: inherit;
    }
  }

  .with-overflow {
    overflow: auto;
  }

  .container {
    @media screen and (min-width: 1230px) {
      width: 80%;
      margin: auto;
    }

    @media screen and (min-width: 1470px) {
      width: 66%;
    }
  }

  @media screen and (min-width: 1025px) {
    .container {
      padding: 0 15px;
    }
  }
}
