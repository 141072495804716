@use "src/styles/inputs.module" as *;

:local {
  .form {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .container {
      display: flex;
    }

    .button-container {
      margin-top: 40px;
    }
  }

  .row {
    width: 60%;
    margin-right: 20px;
  }
}
