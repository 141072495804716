@use "src/styles/AuthFormAssets.module.scss" as *;

:local {
  .form-wrapper {
    background: #2f9dc6;
    background: #2e8cbb;
    background: linear-gradient(to bottom, #2e8cbb 0%, #429fbd 60%, #6abcc7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e8cbb', endColorstr='#6abcc7',GradientType=0 );
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form {
    width: 250px;
    display: block;
    flex: none;
  }
}