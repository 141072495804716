:local {
  .add-role-button {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    border-radius: 50%;
    background: #59b1d1;
    text-transform: uppercase;
    border: 1px solid white;
    color: white;
    text-decoration: none;
    vertical-align: middle;
    text-align: center;
    font-weight: 400;
    margin-left: 7px;
    font-size: 11px;
  }
}
