:local {
  $locked_icon_size: 20px;

  .encounter {
    font-size: 12px;
    cursor: pointer;

    td {
      text-align: center;
      font-weight: 400;
      &:not(:last-child) {
        text-transform: uppercase;
      }
    }

    &:hover {
      background-color: #d5ebf4;
    }

    .code {
      display: block;
    }

    .purpose {
      display: block;
      margin-top: 5px;
      font-size: 9px;
      color: #2f9dc6;
    }
  }

  .select-user-date {
    padding-right: $locked_icon_size;
  }

  .locked-icon {
    vertical-align: middle;
    margin-right: 0;
    width: $locked_icon_size;
    display: initial;
  }
}
