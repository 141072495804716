@use "src/styles/loader" as *;

:local {
  .wrapper {
    composes: loader;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-right: 22px;
    background-color: #f0f0f0;
    text-decoration: none;
  }

  .view-more {
    text-align: center;
    color: #404040;
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .image {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
