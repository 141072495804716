:local {
  .item {
    cursor: pointer;
    &--active {
      background-color: #d5ebf4 !important;
    }
  }

  .family-alive {
    width: 10px;
    max-width: 10px;
    padding: 15px 2px;

    position: absolute;
    left: 0;
    height: 100%;
    background: #2f9dc6;
    top: 0;

    font-size: 1rem;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .family-label {
    margin-left: 10px;
  }

  .no-known-drug-allergies {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
