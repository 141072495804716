:local {

  .icon-status {
    position: absolute;
    bottom: 15%;
    right: -5px;
    font-size: 20px;
    color: #30c6a1;
    border: 1px solid #30c6a1;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-style: normal;
    line-height: 28px;
  }

  .fullDescription {
    display: inline-block;
    max-width: 100%;
    z-index: 7;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -10px;
      display: block;
      border-top: none;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &:before {
      border-top: 10px solid #cccfd1;
      top: 100%;
    }

    &:after {
      top: calc(100% - 1px);
      border-top: 10px solid white;
    }
  }

  .description {
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    position: absolute;
    width: 400px;
    bottom: 70px;
    left: 20px;
    color: black;
    background-color: white;
    z-index: 99999;
    font-size: 16px;
    height: 80px;
  }

  .description::before, .description::after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -20px;
    border: 10px solid transparent;
    border-top: 10px solid white;
  }
  .description::after {
    border-top: 10px solid white;
    bottom: -19px;
  }

}

:global {
  .fullDescription-element {
    z-index: 7;
    text-align: center;
    background: white;
    padding: 8px 20px;
    border-radius: 8px;
    border: 1px solid #c9c9c9;
    max-width: 400px;
    margin-left: 100px;
  }

  .fullDescription-element.fullDescription-element-attached-bottom {
    margin-top: -55px;
  }

  .fullDescription-element.fullDescription-element-attached-top {
    margin-top: -147px;
  }

  .fullDescription-element.fullDescription-element-attached-bottom {
    :local {
      .fullDescription {
        &:before {
          border-top: 10px solid rgba(0, 0, 0, 0.3);
          border-bottom: none;
          top: 100%;
          left: 50%;
        }
        &:after {
          top: calc(100% - 1px);
          border-top: 10px solid white;
          border-bottom: none;
        }
      }
    }
  }
}

.fullDescription-element.fullDescription-element-attached-top {
  :local {
    .fullDescription {
      &:before {
        border-bottom: 10px solid #cccfd1;
        border-top: none;
        bottom: 100%;
      }

      &:after {
        bottom: calc(100% - 1px);
        border-bottom: 10px solid white;
        border-top: none;
      }
    }
  }
}
