:local {
  .signature {
    float: right;

    &-container {
      clear: fix;
    }

    &-label {
      margin-top: 10px;
      padding-top: 5px;
      display: block;
      text-align: center;
      font-size: 16px;
      border-top: 1px solid #969696;
    }

    &-image-container {
      max-width: 200px;
      max-height: 200px;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .report-star {
    position: absolute;
    background-position-x: -19px;
    background-position-y: 5px;
    width: 45px;
    height: 39px;
    background-image: url('./images/report-star.png');
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: -5px;

    @media screen and (min-width: 1070px) {
      width: 65px;
      background-position-x: 0;
      background-position-y: 0;
    }
  }
}
