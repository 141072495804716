:local {
  .wrapper {
    width: 100%;
  }

  .invite-container {
    box-sizing: border-box;
  }

  .input-wrapper {
    margin-top: 10px;
  }

  .button {
    border-radius: 0;
    border: 0;
    color: #fff;
    display: block;
    padding: 6px 12px;
    background-color: #2f9dc6;
    outline: none;
    cursor: pointer;

    &[disabled] {
      background-color: rgb(133, 133, 133);
    }

    &--submit {
      composes: button;

      margin-top: 10px;
    }
  }

  .tooltip {
    background-color: #fff;
    border: 1px solid #2f9dc6;
    margin-top: 10px;
    position: relative;
    padding: 10px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 20px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid;
    }

    &:before {
      border-bottom-color: #2f9dc6;
      top: -5px;
      z-index: 1;
    }

    &:after {
      border-bottom-color: #fff;
      top: -4px;
      z-index: 2;
    }
  }

  .input {
    resize: none;
    width: 100%;
    border: 1px solid #cccfd1;
    padding: 10px 12px 12px 12px;
    font-size: 1em;
    box-sizing: border-box;
    outline: none;
  }

  .message {
    font-size: 14px;
    margin: 5px 0;
    display: block;
  }

  .error-message {
    composes: message;
    color: rgb(162, 44, 44);
  }

  .success-message {
    composes: message;
    color: rgb(44, 162, 45);
  }

}
