@use '../../../../../../../../../styles/triangle' as *;

:local {
  .wrapper {
    padding-top: 10px;
  }

  .scroll-container {
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    max-height: 300px;
  }

  .inner-wrapper {
    border: 1px solid #b3b3b3;
    background: white;
    width: 500px;
    min-height: 312px;
    position: relative;

    &:before {
      position: absolute;
      top: 20%;
      left: 100%;
    }

    &:before {
      @include triangle(right, 10px, 10px, #828282);
      margin-bottom: 1px;
    }
  }

  .table {
    width: 100%;
    text-align: center;
  }

  .no-charts {
    min-height: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
  }
}
