:local {

  @media screen and (max-width: 1200px) {
      .wrapper {
          left: 20%;
      }
  }

  .wrapper {
    min-width: 100%;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: 8px;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    padding: 3px;
    opacity: 0;
    visibility: hidden;
    transform: scale(.9) translateZ(0) translateX(-50%);
    transition: all .3s ease;
    height: 300px;
    overflow: scroll;

    &-active {
      composes: wrapper;
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateX(-50%);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid;
      left: 50%;
      margin-left: -3px;
      top: -6px;
    }

    &:before {
      border-bottom-color: #b3b3b3;
    }

    &:after {
      border-bottom-color: #fff;
      margin-top: 1px;
    }

    &--top {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 35px;

      &:before,
      &:after {
        top: auto;
        bottom: -6px;
      }

      &:before {
        border-bottom: none;
        border-top: 6px solid #b3b3b3;
      }

      &:after {
        border-top: 6px solid white;
        border-bottom: none;
        margin-bottom: 1px;
      }
    }
  }

  .option {
    cursor: pointer;
    padding: 7px 12px;
    background-color: white;
    transition: all .3s ease;
    user-select: none;
    text-align: left;
    color: #2f9dc6;
    box-sizing: border-box;

    &:hover {
      background-color: #60b8d9;
      color: white;
    }

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &-active {
      composes: option;
      background-color: #2f9dc6;
      color: white;
      cursor: default;

      &:hover {
        background-color: #2f9dc6;
      }
    }
  }
}
