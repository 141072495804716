@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    border: 10px;
    box-sizing: border-box;
    display: inline-block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    transform: translate(0px, 0px);
    height: 36px;
    line-height: 36px;
    min-width: 88px;
    color: white;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    text-align: center;
    background-color: $basicEhrBlue;

    &:hover {
      background-color: #267e9e;
    }
  }
}
