@use "src/styles/colors.scss" as *;

:local {
  .no-data {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
}
