:local {
  .patients-search {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    width: 100%;
    pointer-events: none;

    &--opened {
      @media screen and (max-width: 767px) {
        .scroll {
          transform: translate(-100%, 0);
        }
      }
    }
  }

  .patients-list-wrapper {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    z-index: 6;
    position: relative;
    background: white;
    height: 100%;
    pointer-events: all;
    display: flex;
    flex-wrap: nowrap;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;

    @media screen and (min-width: 768px) {
      width: 310px;
    }
  }

  .patient-info-outer-wrapper {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
    // width: 100%;
    pointer-events: all;
    z-index: 10;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    @media screen and (min-width: 768px) {
      text-align: left;
      width: calc(100vw - 390px);
      z-index: 5;

      left: 310px;
    }

    @media screen and (min-width: 1024px) {
      width: 500px;
    }
  }

  .patient-info-wrapper {
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: auto;
    height: 100%;
    box-shadow: 2px 0 3px rgba(0,0,0,0.4);
    background: #f0f0f0;
  }

  .link {
    pointer-events: all;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .inner-wrapper {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    white-space: nowrap;
  }

  .overlay {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 4;
      opacity: 0.3;
      background: black;
    }
  }

  .pagination-wrapper {
    padding-left: 16px;
    margin-top: auto;
  }
}
