@use "src/styles/colors.scss" as *;

:local {
  .done-button {
    padding: 8px;
    background: none;
    border: none;
    border: 1px solid $basicEhrBlue;
    color: $basicEhrBlue;
    border-radius: 35px;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    float: right;

    &:focus,
    &:hover {
      outline: none;
      background: $basicEhrBlue;
      color: white;
    }
  }

  .disabled-button {
    opacity: 0.3;
  }
}
