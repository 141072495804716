@use 'src/styles/colors.scss' as *;

:local {
  .element {
    background: none;
    border: none;
    &:active,
    &:focus {
      outline: none;
    }
  }

  .sign {
    position: relative;
    display: inline-flex;
    width: 14px;
    height: 14px;
    top: 2px;
    border: 1px solid;
    border-radius: 50%;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .positive {
    composes: sign;
    color: #c62f2f;
    border-color: #c62f2f;

    &:before {
      height: 1px;
      width: 6px;
      background-color: #c62f2f;
    }

    &:after {
      width: 1px;
      height: 6px;
      background-color: #c62f2f;
    }
  }

  .negative {
    composes: sign;
    color: #2f9dc6;
    border-color: #2f9dc6;

    &:before {
      height: 2px;
      width: 6px;
      background-color: #2f9dc6;
    }
  }

  .diagnoses {
    color: #2f9dc6;
    font-size: 14px;
    white-space: initial;
  }

  .block-element {
    display: block;
    padding: 5px 0;
    border-top: 1px solid #cccccc;

    &--first {
      composes: block-element;
      border-top: none;
    }
  }

  .with-comma {
    margin: 5px 0;
  }

  .text-padding {
    padding-left: 2px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .white-space {
    white-space: normal;
  }
}
