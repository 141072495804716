@use "src/styles/colors.scss" as *;

:local {
  $width: 36px;
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 5px;
    width: $width;
    height: $width;
    flex: 0 0 $width;
    border-radius: 50%;
    border: 2px solid $basicEhrBlue;
    font-size: 9px;
    color: $basicEhrBlue;
    background: none;
    text-transform: uppercase;
    text-align: center;
    line-height: $width - 2px;

    // ie11 ellipsis fix
    box-sizing: border-box;
    transition: background-color 0.2s ease-in;

    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      top: -5px;
      left: -5px;
      border-radius: 50%;
      border: 1px solid $basicEhrBlue;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover:before {
      opacity: 1;
    }

    &--plus {
      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 50%;
        height: 2px;
        background-color: $basicEhrBlue;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        border: none;
        border-radius: 0;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &:focus,
    &:active {
      outline: none;
    }

    &--disabled {
      border-color: grey;
      color: grey;
    }
  }
}
