:local {
  .wrapper {
    max-height: 190px;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    border: 1px solid #b3b3b3;
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(255,255,255,.5);
  }

  .inner-wrapper {
    padding: 10px 0;
  }

  .diagnosis {
    display: block;
    background: none;
    border: none;
    padding: 5px 10px;
    width: 100%;
    text-align: left;

    &:hover {
      background: rgb(123.2693877551, 196.4530612245, 223.7306122449);
      color: white;
    }

    &:active,
    &--active {
      background: #2f9dc6 !important;
      color: white;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }
  .no-diagnoses-message {
    padding: 5px 10px;
  }
}
