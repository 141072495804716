.wrapper {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 100%;
}

.overflow-container {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.show-more-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.fullRv {
  display: inline-block;
  max-width: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -10px;
    display: block;
    border-top: none;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  &:before {
    border-bottom: 10px solid #cccfd1;
    bottom: 100%;
  }

  &:after {
    bottom: calc(100% - 1px);
    border-bottom: 10px solid white;
  }
}

:global {
  .fullRv-element {
    z-index: 7;
    text-align: center;
    background: white;
    padding: 8px 20px;
    border-radius: 8px;
    border: 1px solid #c9c9c9;
    max-width: 300px;
    // width: 300px;
  }
  .fullRv-element.fullRv-element-attached-bottom {
    margin-top: -10px;
  }

  .fullRv-element.fullRv-element-attached-top {
    margin-top: 10px;
  }

  .fullRv-element.fullRv-element-attached-bottom {
    :local {
      .fullRv {
        &:before {
          border-top: 10px solid rgba(0, 0, 0, 0.3);
          border-bottom: none;
          top: 100%;
          left: 50%;
        }
        &:after {
          top: calc(100% - 1px);
          border-top: 10px solid white;
          border-bottom: none;
        }
      }
    }
  }

  .fullRv-element.fullRv-element-attached-top {
    :local {
      .fullRv {
        &:before {
          border-bottom: 10px solid #cccfd1;
          border-top: none;
          bottom: 100%;
        }

        &:after {
          bottom: calc(100% - 1px);
          border-bottom: 10px solid white;
          border-top: none;
        }
      }
    }
  }
}
