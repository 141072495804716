@use "src/styles/colors.scss" as *;

:local {
  .radiobuttons-title {
    margin-bottom: 0;
  }

  .item {
    display: inline-block;
    vertical-align: middle;
    text-transform: none;
    border: 1px solid #cccfd1;
    padding: 8px 0;
    width: 84px;
    text-align: center;
    cursor: pointer;
    background: white;
    margin-right: 5px;
    outline: none;
    transition: all 0.2s ease;
    margin-top: 8px;

    &--active {
      background-color: $basicEhrBlue;
      color: white;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background: #2584a7;
      color: white;
    }

    &--big {
      width: auto;
      padding: 14px 20px;
    }

    &--notitle {
      margin-top: 0;
    }
  }
}
