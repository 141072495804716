@use "src/styles/loader" as *;

:local {
  .wrapper {
    flex: 1 1 0;
    display: flex;
    position: relative;
    font-size: 12px;
    flex-direction: column;

    // flex-shrink not working in firefox without min-height: 0
    min-height: 0;

    //ie10 needs this to shrink properly
    flex: 1;

    align-items: flex-start;

    table {
      margin: 0;
      height: 100%;
    }
  }

  .outer-wrapper {
    display: flex;
    // ie11 fix
    flex: 1;
    flex: 1 1 0;
    min-height: 0;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .new-chart-button-wrapper {
    font-size: 18px;
    padding: 0 0 15px 0;

    &_with-overlay {
      composes: new-chart-button-wrapper;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }
    }
  }

  .load {
    composes: loader;
    position: relative;
    height: 400px;
    background: #f0f0f0;
  }

  .pagination {
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: 1 0;
    top: -32px;
    margin: 0;

    @media screen and (min-height: 769px) {
      top: -43px;
    }

    &-unsigned {
      composes: pagination;

      position: absolute;
      z-index: 10;
      left: 220px;    
    }
    
    .break-me a {
      color: #fff;
    }

    li {
      padding: 2px;
      border: 1px solid #2f9dc6;
      background-color: #2f9dc6;
      color: #fff;
      margin-right: 2px;
      cursor: pointer;
      outline: none;

      a {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 17px;
        outline: none;
      }
    }

    .active {
      background-color: #fff;
      color: #2f9dc6;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .35);
    z-index: 2;
  }
}

:global {
  .break-me i {
    color: #fff;
    text-decoration: none;
    cursor: default;
  }
}
