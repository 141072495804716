@use "src/styles/colors.scss" as *;
@use 'src/styles/basic.scss' as *;

:local {
  .select {
    display: inline-block;
    height: 30px;
    position: relative;
    vertical-align: middle;
  }

  select {
    visibility: hidden;
    position: absolute;
  }

  .select-body {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding-right: 30px;
    position: relative;
    box-sizing: border-box;

    composes: no-button;
  }

  .label {
    display: block;
    height: 100%;
    max-width: 133px;
    padding: 0 10px;
    background-color: #ffffff;
    border: 1px solid #b3b3b3;
    line-height: 30px;
    box-sizing: border-box;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-with-placeholder {
      composes: label;
      color: #9d9b9b;
    }
  }

  .button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    background-color: $basicEhrBlue;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-45%, -50%);
      border-top: 7px solid white;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 50%;
    right: 42px;
  }
}
