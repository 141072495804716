:local {
  .welcome-banner {
    display: none;
    position: relative;
    overflow: hidden;
  }

  .image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
  }

  .gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0) 59%, rgba(255, 255, 255, 0.65) 100%);
  }

  .plate-container_2 {
    position: absolute;
    bottom: 0;
    letter-spacing: 5px;
    right: 0;
    font-weight: 300;
    font-size: 28px;
    text-transform: uppercase;
    left: 0;
    text-align: center;
    font-family: 'Open Sans';
    color: black;
    display: none;
    height: 137px;
    background: url(./images/line.png) no-repeat;
    background-position: 98% 65%;
    background-size: 60%;

    @media screen and (min-width: 1400px) {
      background-position: 87% 60%;
      background-size: 51%;
    }
  }

  .plate-container {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 50%;
    margin-left: -383.5px;
    display: none;
  }

  .plate-background {
    flex: 1 1 auto;
    background: black;
    opacity: 0.7;
    line-height: 92px;
    padding-left: 60px;
    box-sizing: border-box;
    height: 92px;
    width: 870px;
    font-size: 20px;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 1px;
  }

  .large-letter {
    font-size: 1.5em;
  }

  @media screen and (min-width: 1000px) {
    .welcome-banner {
      display: block;
      width: 76%;
    }
    .image {
      display: block;
      vertical-align: top;
      background: url(./images/main.jpg) right center no-repeat;
      background-size: cover;
    }

    .gradient {
      display: block;
    }

    .plate-container {
      display: flex;
    }

    .plate-container_2 {
      display: block;
    }
  }

  @media screen and (min-width: 1366px) {
    .image {
      background: url(./images/main.jpg) right center no-repeat;
      // background: url(./images/main_m.jpg) 50% 50% no-repeat;
      background-size: cover;
    }
  }
}
