:local {
  .plan-wrapper {
    padding: 27px 24px;
    position: relative;
  }

  .plan-header {
    font-size: 21px;
    line-height: 23.5px;
    text-transform: uppercase;
    color: #2f9dc6;
    margin-bottom: 5px;

    & > div {
      margin-bottom: 5px;
    }
  }

  .edit-card {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 5px;
    top: 3.5em;
    cursor: pointer;
  }

  .billing-info > p {
    margin: 0;
  }

  .plan-seatSet {
    padding-left: 5px;
  }
}