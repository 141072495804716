@use '../../../styles/triangle' as *;

:local {
  .overlay {
    background: white;
    opacity: 0.7;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 7;
  }
  .wrapper {
    padding-top: 10px;
    position: relative;
  }
  .inner-wrapper {
    position: relative;
    box-sizing: border-box;
    -webkit-transition: width 0.3s;
            transition: width 0.3s;

    .arrow-shadow,
    .arrow {
      position: absolute;
      bottom: 100%;
      left: 16%;
      margin-left: -10px;
      z-index: 1;
    }

    .arrow-shadow {
      @include triangle(top, 10px, 10px, #b3b3b3);
    }

    .arrow {
      @include triangle(top, 10px, 10px, white);
      margin-bottom: -1px;
    }
  }

  .list {
    padding: 10px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    max-height: 250px;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
  }

  @media screen and (min-height:768px) {
    .list {
      max-height: 300px;
    }
  }

  .item {
    // display: flex;
    // align-items: center;
    width: 100%;
    padding: 6px 0;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    text-transform: uppercase;
    background: none;
    border: none;
    text-align: left;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .highlight {
    background: #c2efff;
  }

  .checkbox-wrapper {
    & > div {
      padding: 6px 0;
    }
  }

  .item-name {
    // 54px - total width of AddButton (width + border + margin)
    display: inline-block;
    vertical-align: middle;
    width: 80%;

    &--ros {
      width: calc(100% - 150px);
    }
  }

  .broadMatch {
    background: #fafad2 !important;
  }

  .exactMatch {
    background: #c3e8e3 !important;
  }
}

:global {
  //.elementSearch-element.elementSearch-out-of-bounds-top,
  //.elementSearch-element.elementSearch-out-of-bounds-bottom {
  //  // display: none doesn't work!!!
  //  position: fixed !important;
  //  top: -9000px !important;
  //  left: -9000px !important;
  //}

  .search-results__popper {
    z-index: 10 !important;
    &[data-placement^='top'] {
      :local {
        .wrapper {
          padding-top: 0;
          padding-bottom: 10px;
        }

        .inner-wrapper {
          .arrow-shadow,
          .arrow {
            bottom: initial;
            top: 100%;
          }

          .arrow-shadow {
            @include triangle(bottom, 10px, 10px, #b3b3b3);
          }

          .arrow {
            @include triangle(bottom, 10px, 10px, white);
            margin-bottom: 0;
            margin-top: -1px;
          }
        }
      }
    }
  }

  .elementSearch {
    &-element {
      z-index: 6;
    }
    &-element-attached-bottom {
      :local {
        .wrapper {
          padding-top: 0;
          padding-bottom: 10px;
        }

        .inner-wrapper {
          .arrow-shadow,
          .arrow {
            bottom: initial;
            top: 100%;
          }

          .arrow-shadow {
            @include triangle(bottom, 10px, 10px, #b3b3b3);
          }

          .arrow {
            @include triangle(bottom, 10px, 10px, white);
            margin-bottom: 0;
            margin-top: -1px;
          }
        }
      }
    }
  }
}
