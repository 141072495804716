@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    display: inline-block;
    padding: 10px 0;
    @media screen and (min-height: 769px)  and (min-width: 490px) {
      padding: 13px 0;
    }
  }
}
