@use "src/styles/colors.scss" as *;

:local {
  $mdWidth: 80%;
  $lgWidth: 66%;

  .inner-wrapper {
    &--view {
      margin: 0 auto;
      border: 1px solid lightgray;
    }
  }

  .patient-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .colontitle-wrapper {
    padding: 0 80px;

    .colontitle {
      padding: 5px 0;
      border-top: 2px solid black;
      text-align: right;
    }
  }

  @media screen and (min-width: 1230px) {
    .inner-wrapper {
      &--view {
        width: $mdWidth;
      }
    }

    .patient-wrapper {
      width: $mdWidth;
    }
  }


  @media screen and (min-width: 1470px) {
    .inner-wrapper {
      &--view {
        width: $lgWidth;
      }
    }

    .patient-wrapper {
      width: $lgWidth;
    }
  }
}
