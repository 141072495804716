:local {
  .wrapper {
    display: table-cell;
    vertical-align: top;
    padding: 15px 0.5em 0.5em;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
    // border-right: 1px solid rgba(238, 238, 238, 0.31);

    .hx-abbr {
      text-transform: none;
    }

    &:first-child {
      // border-left: 1px solid rgba(238, 238, 238, 0.31);
    }

    &:hover {
      .title {
        text-decoration: underline;
      }
    }

    @media screen and (min-width: 870px) {
      padding: 15px 1.5em 0.5em;
      font-size: 11px;
    }
  }

  .disabled {
    cursor: not-allowed;
    .title {
      text-decoration: line-through;
    }
    &:hover {
      .title {
        text-decoration: line-through;
      }
    }
  }

  .wrapper-inner {
    composes: wrapper;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 12px;
    line-height: 1;
    background-color: #dedede;
    color: #000000;
  }

  .wrapper-inner .subtitle {
    display: none;
  }

  .subtitle {
    text-transform: none;
    text-align: center;
    display: block;
    font-weight: 300;
    font-size: 11px;
    color: #f0f0f0;
  }

  .wrapper--active {
    composes: wrapper;
    color: inherit;
    background: #f0f0f0;

    .subtitle {
      color: #2f9dc6 !important;

      * {
        color: #2f9dc6 !important;
      }
    }
  }

  .wrapper-inner--active {
    composes: wrapper-inner;
    color: inherit;
    background: #ffffff;

    &:first-child {
      border-left: 1px solid rgba(238, 238, 238, 0.31);
    }
  }

  .title {
    position: relative;
  }

  .mark {
    display: block;
    position: absolute;
    top: 2px;
    right: -15px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 2px solid white;
    background: #ff9000;
  }
}
