@use 'src/styles/basic';

:local {

  $circle-width: 60px;
  $seatStatusFont-size: 32px;
  $blue: rgb(27, 196, 251);
  $green: #30c6a1;
  $red: rgb(254, 83, 65);
  $blue-background: rgb(64, 228, 243);

  @mixin statusIconDescription() {
    content: "?";
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: black;
    font-size: 15px;
    border: 1px solid $blue-background;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    background-color: $blue-background;
  }

  @mixin seatStatusIcon($color, $font-size) {
    width: $circle-width;
    height: $circle-width;
    font-size: $font-size;
    border: 1px solid $color;
    border-radius: 50px;
    background-color: $color;
    color: white;
    font-weight: 600
  }

  @mixin planCircleBorder($color) {
    border: 3px solid $color;
    border-radius: 50px;
  }

  @mixin seatStatusIconBefore($content) {
    content: $content;
    display: block;
    transform: translateY(15%);
  }

  .estimate {
    transform: translateY(50%);
  }

  .image-lock {
    //position: relative;
    //@include seatStatusIcon($red, 13px);
    @include planCircleBorder($red);

    i {
      @include statusIconDescription;
    }
  }

  .seat-status__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .planImage {
    width: 60px;
    height: 60px;
    transition: background-color 0.2s ease-in;
    margin: 0;
  }

  .pro1 {
    background: url(../../../../../../../../../../images/Plan1.svg) center no-repeat;
    background-size: cover;
  }

  .pro2 {
    background: url(../../../../../../../../../../images/Plan2.svg) center no-repeat;
    background-size: cover;
  }

  .vipPlan {
    background: url(../../../../../../../../../../images/PlanVIP.svg) center no-repeat;
    background-size: cover;
  }

  .image-open {
    //position: relative;
    //@include seatStatusIcon($green, $seatStatusFont-size);
    @include planCircleBorder($green);

    i {
      @include statusIconDescription;
    }

  }

  //.image-open:before {
  //  @include seatStatusIconBefore("O")
  //}

  .image-used {
    //position: relative;
    //@include seatStatusIcon($blue, $seatStatusFont-size);
    @include planCircleBorder($blue);

    i {
      @include statusIconDescription;
    }
  }

  //.image-used:before {
  //  @include seatStatusIconBefore("U")
  //}

  .hours {
    padding-bottom: 15px;
  }

  .dropdown-wrapper {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.8);
    z-index: 10;
  }

  .buy-button {
    background-color: #2f9dc6;
    padding: 8px 15px;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    border: none;
    z-index: 11;
    transition: background-color .3s ease;
    outline: none;

    &:hover {
      background-color: rgb(123.2693877551, 196.4530612245, 223.7306122449);
    }
  }

  .blocked-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    font-size: 14px;
    font-weight: bold;
    color: rgb(204, 34, 34);
    width: 100%;
    text-align: center;
    padding-top: 15px;
  }
}
