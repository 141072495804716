:local {
  .warning {
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    text-align: center;
  }
}
