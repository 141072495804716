@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
