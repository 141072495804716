:local {
  .wrapper {
    position: relative;
    z-index: 6;
    box-shadow: 0 4px 4px rgba(1,1,1,0.3);
    padding: 4px 110px;
    color: #6f8b97;
    background: #ffffff;
    text-align: center;
    font-size: 1rem;
    display: flex;
    flex-direction: column;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  .linkWrapper {
    position: absolute;
    right: 15px;
    top: 4px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    height: 41px;

    button {
      border: none;
      background: none;
      outline: none;
      &:hover {
        color: #267e9e;
      }
    }
  }

  .burger-menu-wrapper {
    position: absolute;
    left: 15px;
    top: 15.5px;
  }

  .lock-button {
    margin-right: 10px;
    height: 41px;

    &:hover {
      .lock-icon {
        background-position: -42px 0;
      }
    }

    &:active {
      .lock-icon {
        background-position: -84px 0;
      }
    }
  }

  .lock-icon {
    width: 41px;
    height: 41px;
    display: inline-block;
    background-image: url('../../../../../images/lock_sprite.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 125px 41px;
  }

  .loader {
    cursor: default;
    margin-right: 10px;
    &:after {
      content: '';
      position: static;
      display: block;
      width: 37px;
      height: 37px;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      border: 2px solid #2f9dc6;
      border-top-color: white;
      animation: rotate infinite linear 2s;
    }
  }
}


@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
