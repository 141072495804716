:local {
  $buttonDefaultRadius: 20px;

  .button {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    border: 1px solid #2f9dc6;
    width: $buttonDefaultRadius * 2;
    height: $buttonDefaultRadius * 2;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-transition: background-color 0.2s ease-in;
    padding: 0;
    background-image: url(./door.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: white;
    transition: background-color 0.2s ease-in;

    &:hover {
      background-color: rgb(189.2155102041, 225.7624489796, 239.3844897959);
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
}
