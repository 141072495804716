@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 22px;
      height: 22px;
      background: url(./images/icon.png) no-repeat;
      background-size: contain;
    }
  }

  .label {
    display: inline-block;
    vertical-align: middle;
    color: #30c6a1;
  }
}
