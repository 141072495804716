@use "src/styles/colors.scss" as *;

:local {
  .payment-notification {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;

    &-container {
      width: 100%;
      height: 100%;
    }

    &-overlay {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
    }

    &-content {
      width: 30%;
      min-width: 300px;
      position: absolute;
      left: 50%;
      top: 50%;
      background: white;
      transform: translate(-50%, -50%);
    }

    &--active {
      z-index: 999;
      opacity: 1;
      visibility: visible;
    }

    &-item {
      text-align: center;
    }

    &-controls {
      padding: 0 0 15px;
      font-size: 0.85em;
    }

    &-title {
      font-size: 1.2em;
      padding: 14px 24px;
      color: #3c8cbb;
      border-bottom: 4px solid  #3c8cbb;
      &-info {
        border-bottom: 4px solid  #3c8cbb;
      }
      &-warning {
        // border-bottom: 4px solid  #ff7e00;
        color: #ff7e00;
      }
      &-error {
        // border-bottom: 4px solid  #c62f2f;
        color: #c62f2f;
      }
    }

    &-data {
      padding: 10px 20px;
    }
  }

  .message {
    padding-top: 20px;
  }

  .payment-notification-seconds {
    color: grey;
    font-size: 10px;
  }

  .days-to-read-only {
    color: #3c8cbb;
    font-size: 14.4px;
  }

  .warning-triangle {
    vertical-align: middle;
    margin-right: 10px;
    width: 27px;
  }

  .button {
    margin: 0 10px;
    padding: 0.6em 1.2em;
    outline: none;
    vertical-align: middle;
    font-size: 1em;
    font-weight: 600;
    -webkit-transition: all 0.3s;
            transition: all 0.3s;
    color: #aaaaaa;
    cursor: pointer;
    background: transparent;
    border: 1px solid #aaaaaa;
    text-decoration: none;

    &--main {
      background: #3c8cbb;
      border-color: #3c8cbb;
      color: white;
    }

    &:hover {
      border-color: $basicEhrBlue;
      color: #ffffff;
      background-color: $basicEhrBlue;
    }

    &:disabled {
      background: #aaaaaa;
      cursor: not-allowed;
      color: white;
      &:hover {
        border-color: #aaaaaa;
      }
    }
  }
}
