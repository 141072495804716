.control-button {
  width: 35px !important;
  height: 35px !important;

  &-wrapper {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.save-button-wrapper {
  right: 40px;
}

@media (hover: hover) and (pointer: fine) {
  .edit-button {
    display: none;
  }

  .wrapper:hover {
    .edit-button {
      display: block;
    }
  }
}

.edit-button {
  margin-right: 0;
}

.wrapper {
  position: relative;
  padding-right: 41px;
  &--edit {
    padding-right: 78px;
  }

  .input {
    width: 64%;
  }
}


