@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    z-index: 7;
    position: relative;

    @media screen and (min-width: 855px) {
      font-size: 18px;
    }
  }

  .panel {
    display: inline-block;
    border: 1px solid #b3b3b3;
    border-radius: 30px;
    padding: 6px;
    color: $basicEhrBlue;
    background: white;

    & > * {
      vertical-align: middle;
    }
  }

  .link {
    text-decoration: none;
    background: $basicEhrBlue;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    padding: 7px 25px;
    display: inline-block;
    margin-left: 13px;

    &:hover {
      background: rgb(82.0530612245, 178.1346938776, 213.9469387755);
    }

    &:active {
      background: darken($basicEhrBlue, 10%);
    }
  }

  .warning-triangle {
    vertical-align: middle;
    margin-right: 10px;
    width: 27px;
    margin-left: 11px;
  }

  .message {
    margin-right: 10px;

    &-warning {
      color: #ff7e00;
    }
    &-error {
      color: #c62f2f;
    }
  }
}
