:local {
  .container {
    display: flex;
    justify-content: left;
  }

  .name {
    flex: 0.5 1 0;
  }

  .email {
    flex: 1 1 0;
  }
}
