:local {
  .wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .patient-data {
    vertical-align: top;
    width: 55%;
    margin-right: 15px;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
    overflow-x: hidden;

    &--wide {
      composes: patient-data;

      width: 100%;
      margin-right: 0;
    }
  }
}
