:local {
  .justify {
    text-align: justify;
    font-size: 0;

    &:after {
       content: '';
       display: inline-block;
       width: 100%;
     }
  }
}
