:local {
  tr.item {
    cursor: pointer;
    &--active {
      background-color: #d5ebf4 !important;
    }
  }

  .no-known-drug-allergies {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
