@use "src/styles/colors.scss" as *;

:local {
  .pdf-link {
    display: none;
  }

  .action-button {
    background: none;
    border: 1px solid $basicEhrBlue;
    text-align: center;
    color: $basicEhrBlue;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    height: 50px;
    padding: 15px;
    border-radius: 35px;
    font-size: 13px;
    text-decoration: none;
    position: relative;

    &:hover,
    &:focus {
      background-color: $basicEhrBlue;
      color: white;
      outline: none;
    }

    &:active {
      outline: none;
      background-color: darken($basicEhrBlue, 10%);
    }

    & + .action-button {
      margin-left: 10px;
    }
  }

  .round-button {
    composes: action-button;

    padding: 0;
    width: 50px;
    border-radius: 50%;
  }

  .round-button:disabled {
    border-color: transparent;
    &:after {
      position: absolute;
      background: rgba(255,255,255, 0.5);
      z-index: 1;
      width: 50px;
      height: 50px;
      left: -3px;
      right: 0;
      top: -3px;
      bottom: 0;
      content: '';
      display: inline-block;
      border-radius: 50%;
      border: 2px solid $basicEhrBlue;
      border-top-color: white;
      animation: rotate infinite linear 2s;
      vertical-align: middle;
    }

    &:hover,
    &:focus {
      background: none;
      color: $basicEhrBlue;
      outline: none;
    }

    &:active {
      outline: none;
      background-color: transparent;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
