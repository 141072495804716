@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.2rem;
    padding-right: 40px;
    cursor: pointer;
    position: relative;

    span {
      vertical-align: middle;
    }

    .icon {
      transition: all .3s ease;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid $basicEhrBlue;
      background-color: $basicEhrBlue;

      &:before,
      &:after {
        content: '';
        position: absolute;
        // top: 50%;
        // left: 50%;
        top: 0;
        left: 0;
        transform: scale(.7);
        transition: opacity .3s ease;
        width: 100%;
        height: 100%;
      }

      &:before {
        background: url('./images/pn-arrows-light.png') center center no-repeat;
        opacity: 1;
      }

      &:after {
        background: url('./images/pn-arrows-dark.png') center center no-repeat;
        opacity: 0;
      }
    }

    &:hover .icon {
      background-color: #fff;

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }



  @media screen and (min-height: 769px) and (min-width: 490px) {
    .wrapper {
      &:after {
        width: 46px;
        height: 46px;
      }
    }
  }
}
