@mixin cross($width, $color) {
  position: relative;

  &:before,
  &:after {
    content: '';
    width: $width;
    height: 1px;
    background: $color;
    left: 50%;
    top: 50%;
    position: absolute;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
