@use '../../../../../../../../../../styles/input-variables.module' as *;
@use '../../../../../../../../../../styles/inputs.module';
@use "../../../../../../../../../../styles/colors" as *;

@mixin hostedField() {
  border: 1px solid #b3b3b3;
  height: 30px;
  padding: 5px;
  outline: none;
  font-size: 16px;
}

$payment_input_weight: 220px;

:local {
  .modal-form-load-overlay {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.85;
    background: white;
    z-index: 100;
  }

  .form-container {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
  }

  .user-info-container {
    display: flex;
  }

  .loader {
    width: 15px;
    height: 15px;
    position: absolute;
    right: -35px;
    left: initial;
    top: 50%;
  }

  .modal-window {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;

    &-container {
      width: 100%;
      height: 100%;
    }

    &-overlay {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
    }

    &-content {
      width: 30%;
      min-width: 500px;
      position: absolute;
      left: 50%;
      top: 50%;
      background: white;
      transform: translate(-50%, -50%);
    }

    &--active {
      z-index: 999;
      opacity: 1;
      visibility: visible;
    }

    &-controls {
      display: flex;
      justify-content: center;
      padding: 15px 0 20px;
      font-size: 0.85em;
    }

    &-title {
      font-size: 1.2em;
      padding: 14px 24px;
      color: #3c8cbb;
      border-bottom: 4px solid  #3c8cbb;
      text-transform: uppercase;
      text-align: center;
    }

    label {
      display: block;
      margin-bottom: 4px;
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  .button {
    margin: 0 10px;
    padding: 0.6em 1.2em;
    outline: none;
    position: relative;
    font-size: 1em;
    font-weight: 600;
    -webkit-transition: all 0.3s;
            transition: all 0.3s;
    color: #aaaaaa;
    cursor: pointer;
    background: transparent;
    border: 1px solid #aaaaaa;

    &:hover {
      border-color: #2f9dc6;
      color: #ffffff;
      background-color: #2f9dc6;
    }

    &:disabled {
      background: #aaaaaa;
      cursor: not-allowed;
      color: white;
      &:hover {
        border-color: #aaaaaa;
      }
    }
  }

  .billing {
    padding: 25px 20px;
    display: flex;
    justify-content: space-between;

    & > form {
      width: 100%;
    }

    .modal-window-data {
      margin-top: 40px;
    }

    &-user-info {
      position: relative;
      input {
        width: $payment_input_weight;
        max-width: 100%;
        @include hostedField()
      }
      margin-bottom: 20px;
    }

    &-card {
      width: $payment_input_weight;
      max-width: 100%;
      margin-bottom: 20px;
    }

    &-cvv {
      width: 119px;
    }

    &-postal-code {
      display: flex;
      flex-direction: column;
      input {
        @include hostedField();
        width: 119px;
      }
    }

    &-expiration-date {
      width: 140px;
      margin-bottom: 20px;
    }
  }

  .modal-element-wrapper {
    margin-bottom: 10px;
  }

  .error-wrapper {
    margin-top: 25px;
    margin-left: 25px;
  }

  .card-error {
    color: white;
    background-color: $formErrors;
    padding: 2px 10px;
    margin-bottom: 2px;
    width: 210px;
  }

  .card-success {
    color: white;
    background-color: #72e07b;
    padding: 2px 10px;
    width: 210px;
  }

  .text-confirm-input-wrapper {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .text-confirm-input {
    padding: 2px 10px;
    border: 1px solid #b3b3b3;
    box-sizing: border-box;
    text-align: center;
    margin-top: 5px;
  }
}

:global {
  .hosted-field {
    @include hostedField()
  }

  .StripeElement {
    @include hostedField()
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
}
