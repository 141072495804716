@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    position: relative;
    background-color: white;
  }

  .clear-container {

    .image-wrapper {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: none;
      background: url(../../images/close.png) center no-repeat;
      background-size: cover;
      transition: background-color 0.2s ease-in;
      position: absolute;
      right: 0;
      top: 30%;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.5);
    z-index: 25;
  }

  .disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(255,255,255,.5);
  }
}

:global {
  .seat-user-select {
    position: relative;

    .Select-clear {
      width: 25px;
      border: 1px solid red;
      border-radius: 50px;
      height: 25px;
      font-size: 24px;
      color: red;
    }

    .Select-arrow-zone {
      display: none;
    }

    .Select-placeholder {
      z-index: 2;
      width: 100%;
    }

    .Select-menu-outer {
      z-index: 20;
    }

    .Select-option {
      display: inline-block;
      &.is-focused {
        background-color: white;
      }

      &:hover {
        background-color: rgba(47, 157, 198, .4);
      }
    }

    .Select-value {
      padding: 0 !important;
    }

    .Select-value-label {
      max-width: 100%;
    }
  }
}
