:local {
  .content {
    position: relative;
    z-index: 1;
    width: 100%;
    background: white;
  }

  .listWrapper {
    position: relative;
    width: 100%;
    display: block;
    overflow: auto;
  }

  .search-header {
    padding: 16px;
  }

  .buttonWrapper {
    margin: 22px 0 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
  }

  .error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
  }

  .header {
    font-size: 16px;
    padding: 0 16px 0 16px;
    color: #2f9dc6;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .import {
    width: 100% !important;
  }

  .import-wrapper {
    padding: 10px;
    :global {
      .generic-button {
        width: 100%;
      }
    }
  }
}
