@use "src/styles/colors.scss" as *;

:local {
  .arrow {
		font-size: large;
    padding-left: 5px;
    color: $basicEhrBlue;
    cursor: pointer;
    background: none;
    border: none;
  }

  .arrow-down {
    composes: arrow;
    transform: rotate(90deg)
  }
}