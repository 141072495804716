:local {
  .page {
    height: 100%;
    display: flex;
    flex: 1 1 0;
    min-height: 0;
    flex-direction: column;
    padding-top: 30px;
  }

  .tabs-wrapper {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
  }

  .wrapper {
    display: flex;
    background: white;
    padding: 5px 5px 0;
    flex: 1;
    position: relative;

    @media screen and (min-width: 1025px) {
      padding: 15px 15px 0;
    }
  }
}
