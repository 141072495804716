@use  "src/styles/basic" as *;

:local {
  .container {
    position: relative;
    border: 1px solid #b3b3b3;
    border-radius: 30px;
    padding: 10px;
  }

  .title {
    font-size: 1.2rem;
  }

  .clear-icon__container {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    composes: no-button;
    width: auto;

    .clear-icon {
      transform: translate(50%);
    }
  }
}
