@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    width: 50%;
    padding: 15px 15px 0 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .control-button-wrapper {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .list-wrapper {
    background: #e8f0f3;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
  }

  .scrollable-wrapper {
    padding: 0 15px;
    overflow: auto;
    -webkit-overflow-scrolling: auto;

    //ie10 overflow fix
    flex: 1;
  }

  .search-form__wrapper {
    margin-top: 15px;
  }

  .item {
    position: relative;
    margin-top: 3px;
  }

  .item:last-child {
    margin-bottom: 15px;
  }

  .item--selected {
    opacity: 0.5;
  }

  .item--hidden {
    display: none;
  }

  .filter-button-wrapper {
    padding: 0 0 10px;
  }

  .filter-button {
    padding: 8px;
    background: none;
    border: none;
    border: 1px solid $basicEhrBlue;
    color: $basicEhrBlue;
    border-radius: 35px;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;

    &:focus,
    &:hover {
      outline: none;
      background: $basicEhrBlue;
      color: white;
    }

    &:active,
    &--active {
      outline: none;
      background: darken($basicEhrBlue, 10%) !important;
      color: white;
    }
  }
}

:global {
  .elementsViewItems-subelements {
    &-element {
      z-index: 6;
    }
  }

  .elementsViewItems-subelements-element.elementsViewItems-subelements-out-of-bounds-top,
  .elementsViewItems-subelements-element.elementsViewItems-subelements-out-of-bounds-bottom {
    // display: none doesn't work!!!
    position: fixed !important;
    top: -9000px !important;
    left: -9000px !important;
  }
}
