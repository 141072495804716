:local {
  .edit-form {
    /*display: table-cell;*/
    position: absolute;
    vertical-align: top;
    width: 45%;
    margin-top: 0;
    /*padding: 15px;*/
    background: #e8f0f3;
    box-sizing: border-box;
    overflow: hidden;
    /*height: 100%;*/
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: auto;

    &-wrapper {
      // ie11 fix for social tab
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
    }
  }
}
