@use 'src/styles/cross' as *;

:local {
  .button {
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    @include cross(100%, black);
  }
}
