:local {
  .wrapper {
    width: 100%;
    position: relative;
    min-height: 42px;
    margin-top: 15px;
  }

  .input-wrapper {
    position: relative;
  }

  .loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(255,255,255);
    // opacity: .7;
  }

  .input {
    border: 1px solid #cccccc;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    margin-top: 10px;
    display: block;
    resize: none;
  }

  .title {
    // text-transform: uppercase;
    margin-top: 15px;
  }

  .footer {
    text-align: right;
    padding-top: 8px;
  }
}
