@use "src/styles/colors.scss" as *;

:local {
  .table {
    width: 100%;
    background: white;
    table-layout: fixed;
    &-with-border {
      border: 1px solid #acb2b4;
    }

    thead {
      background: #828282;
      color: white;
    }

    th,
    td {
      position: relative;
      text-align: center;
      padding: 8px 5px;
      font-weight: 300;

      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
          word-break: break-all;
      /* Instead use this non-standard one: */
          word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
         -moz-hyphens: auto;
      -webkit-hyphens: auto;
              hyphens: auto;
    }

    tr {
      transition: background-color 0.2s;
      &:nth-of-type(2n) {
        background: #f4fafc;
      }
    }

    th {
      color: white;
      text-transform: uppercase;
    }

    &-blue {
      composes: table;
      thead {
        background: $basicEhrBlue;
      }
    }
  }

  .table-body-gray {
    background: #f0f0f0;
    tr:nth-of-type(2n) {
      background: transparent;
    }
  }

  .viewport {
    width: 100%;
    overflow: auto;
    display: flex;
    min-height: 0;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
}
