@use "src/styles/colors.scss" as *;
@use "src/styles/basic.scss" as *;

:local {
  $inputOffset: 37px;

  .validation-error-custom {
    composes: validation-error;
    top: 90%;
  }

  .full-width {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .invite-container {
    padding: 15px;
    composes: full-width
  }

  .severity-button {
    width: 81px !important;
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    :global {
      .login,
      .password,
      .quick-password {
        .validation-error {
          display: block;
        }
      }

      .password {
        .validation-error {
          position: initial;
        }
      }

      .Select {
        &:before {
          content: '';
          width: 22px;
          height: 22px;
          background: url('../../images/input-icon.png') 0 0 no-repeat;
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 1;
          pointer-events: none;
        }
      }

      .Select-control,
      .Select-placeholder,
      .Select-value {
        padding-left: $inputOffset !important;
      }

      .Select--multi .Select-value {
        padding-left: 0 !important;
      }

      .Select-input {
        margin-left: 0;
        padding-left: 0;
      }

      .Select-menu-outer {
        z-index: 2;
      }
    }
  }

  .error {
    color: red;
    font-size: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row {
    position: relative;
    margin: 13px 0;
  }

  .pair-dates {
    composes: row;
    display: flex;
    flex-direction: column;

    .datepicker:first-child {
      margin-right: 50px;
    }

    @media screen and (min-width: 883px) {
      flex-direction: row;
    }
  }

  .name {
    line-height: 1em;
    font-weight: 600;
    font-size: 1.6em;
    color: #404040;
    text-transform: uppercase;
  }

  .prescribed {
    &-title {
      margin-bottom: 8px;
      text-transform: uppercase;
      display: block;
      font-weight: 400;
      color: $basicEhrBlue;
    }
  }

  @media screen and (min-width: 992px) {
    .status-wrapper {
      display: inline-block;
      vertical-align: middle;
      width: 60%;
    }

    .prescribed {
      display: inline-block;
      vertical-align: middle;
      width: 40%;
      text-align: center;
    }
  }

  @media screen and (min-width: 1025px) {
    .row {
      margin: 15px 0;
    }
  }
}
