:local {
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
    z-index: 2;
  }

  .tabsWrapper {
    flex: 1 1 auto;
    display: flex;
    /* firefox needs this for flex-shrink to work */
    min-height: 0;
    /*padding-top: 40px;*/
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .error-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .fms-completion {
    font-weight: 600;
  }

  .warning {
    color: #ffbc65;
  }

  .success {
    color: greenyellow;
  }
}
