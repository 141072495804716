@use 'src/styles/input-variables.module.scss' as *;

:local {
  .wrapper {
    padding: 34px 24px;
    height: 100%;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
  }

  .clinic-item {
    padding: 13px 0;
    transition: background-color .3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    overflow: hidden;
    
    &:hover {
      background-color: #cde6f2;
    }
  }

  .icon {
    flex-grow: 0;
  }
  
  .info {
    flex-grow: 1;
  }

  .title {
    font-size: 16px;
  }

  .address {
    color: $colorBlue;
    font-size: 13px;
    overflow: hidden;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .title,
  .address {
    padding-left: 10px;
  }

  .add-button {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    border: 1px solid $colorBlue;
    display: block;
    margin-left: 5px;

    &:after {
      content: 'Use';
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      color: $colorBlue;
      font-size: 12px;
      text-transform: uppercase;
      font-style: normal;
    }
  }

  .search-wrapper {
    margin-bottom: 30px;

    input[type="text"] {
      background-color: #ececec !important;
    }
  }

  .input-wrapper {
    padding-left: 9px;
  }

  .search-title {
    padding-left: 9px;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;

    b {
      font-size: 22px;
      font-weight: 400;
    }
  }

  .clinics-list {
    overflow: auto;
  }
}
