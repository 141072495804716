@use '../../../../../../../../../../styles/loader.module';

:local {
  .container {
    tbody {
      tr.item {
        cursor: pointer;
        &--active {
          background-color: #d5ebf4
        }
      }
    }
  }
}