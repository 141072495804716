@use "src/styles/colors.scss" as *;

:local {
  .about-item {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px !important;
    text-transform: lowercase !important;
  }
}
