.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  font-size: 14px;
  cursor: pointer;
}

.report {
  cursor: default;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 87%;
  min-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  background: #ffffff;
  z-index: 9;
  box-sizing: border-box;
  // transition:         transform 1s ease;
  box-shadow: -2px 0 3px rgba(0,0,0,0.3);
  border: 1px solid lightgray;

  @media screen and (min-width: 1024px) {
    width: 83%;
  }

  @media screen and (min-width: 1230px) {
    width: 65%;
  }
}
