@use "src/styles/colors.scss" as *;

.save {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0);
  background: white url('../../images/round-checkmark.png') center no-repeat;
  background-size: contain;
  outline: none !important;

  &--loading {
    position: static;
    // left: 0;
    border: 2px solid $basicEhrBlue;
    background: white;
    top: 0;
    animation: rotate infinite linear 2s;
    border-top-color: white;
  }

  &--disabled {
    composes: save;
    opacity: .5;
    cursor: default;
  }

  @keyframes rotate {
    from {
      transform: translate(0, 0) rotate(0);
    }

    to {
      transform: translate(0, 0) rotate(360deg);
    }
  }
}

.button-large {
  width: 40px;
  height: 40px;
}
