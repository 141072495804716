@use './src/styles/basic.scss' as *;
@use './src/styles/colors.scss' as *;

:local {
  .input__container {
    position: relative;
    display: flex;

    .input {
      width: 100%;
      height: 40px;
      border: 1px solid #cccfd1;
      padding: 10px 12px 12px 37px;
      font-size: 1em;
    }
  }

  .dropdown-container {
    z-index: 10;
    position: absolute;
    width: 100%;
    margin-top: 8px;
    background-color: white;
    max-height: 200px;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid #cccfd1;
  }

  .option-container {
    padding: 8px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .option-container:hover {
    color: white;
    background-color: $basicEhrBlue;
  }

  .remove-option {
    position: absolute;
    color: red;
    text-align: center;
    right: 8px;

    button {
      width: 18px;
      border: 1px solid red;
      border-radius: 50%;
      height: 18px;
    }
  }

  .remove-option:hover {
    cursor: pointer;
  }

  .close-options {
    composes: no-button;
    text-align: center;
    font-size: 1.5em;
    background-color: $lightBasic;
  }

  .multi-input__container {
    position: relative;
    border: 1px solid #cccfd1;
    display: flex;
    background-color: white;
    height: auto;

    .options {
      height: 100%;
      display: flex;
      flex: 1 1 0;
      flex-wrap: wrap;
      padding-left: 37px;
    }

    .input-wrapper {
      width: 100%;
      margin-top: 5px;
    }

    .multi-input {
      border: none;
      width: 100%;
      padding: 10px 12px 12px 37px;
    }

    .search-icon__container {
      width: 40px;
      background: $basicEhrBlue;
      display: flex;
      align-items: center;
    }
  }

  .multi-option__container {
    background-color: rgb(230, 230, 230);
    display: flex;
    margin: 2px;
    align-items: center;

    .label {
      font-size: 85%;
      overflow: hidden;
      padding: 3px 3px 3px 6px;
      text-overflow: ellipsis;
      white-space: initial;
    }

    .clear-icon__container {
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      height: 100%;
      align-items: center;

      .clear-icon {
        display: inline-block;
        fill: currentcolor;
        line-height: 1;
        stroke: currentcolor;
        stroke-width: 0;
      }
    }

    .clear-icon__container:hover {
      background-color: rgb(255, 189, 173);
      color: rgb(222, 53, 11);
    }
  }

  .content {
    display: flex;
  }

  .search-icon__container {
    display: flex;
    height: auto;
    width: 40px;
    min-width: 40px;
    background: $basicEhrBlue;
    padding: 8px;
    justify-content: center;
    align-items: center;
  }

  .search-icon__container:hover {
    cursor: pointer;
  }

  .search-icon {
    background: url("../search-icon.png") 50% no-repeat;
    position: absolute;
    right: 10px;
    z-index: 1;
    pointer-events: none;
    width: 20px;
    height: 25px;
    background-size: contain;
  }
}
