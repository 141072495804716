:local {
  .info {
    font-size: 1rem;
    vertical-align: middle;
    display: inline-block;
    // align-items: center;
  }

  .username {
    margin: 0;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
  }

  .about {
    margin-left: 0;
  }

  .about-item {
    color: #73b7d5;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
}
