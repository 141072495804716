$base-line-height: 24px;
$white: rgb(255,255,255);
$off-white: rgba($white, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: $white;
  }
}

@mixin loading($base-line-height: $base-line-height) {
    display: block;
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .25rem solid $off-white;
    border-top-color: $white;
    animation: spin $spin-duration infinite linear;
  &--double {
    border-style: double;
    border-width: .5rem;
  }
}

@mixin loading-pulse($base-line-height: $base-line-height) {
  display: block;
  position: relative;
  width: ($base-line-height / 4);
  height: $base-line-height;
  background: $off-white;
  animation: pulse $pulse-duration infinite;
  animation-delay: ($pulse-duration / 3);
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    height: ($base-line-height / 1.5);
    width: ($base-line-height / 4);
    background: $off-white;
    top: 50%;
    transform: translateY(-50%);
    animation: pulse $pulse-duration infinite;
  }
  &:before {
    left: -($base-line-height / 2);
  }
  &:after {
    left: ($base-line-height / 2);
    animation-delay: ($pulse-duration / 1.5);
  }
}

:local {
  $height: 14px;

  .button-loading-icon {

    @include loading($height);

    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -$height/2;
    right: 14px;
    z-index: 5;
  }

  .select-loading-icon {
    composes: button-loading-icon;

    left: 50%;
    margin-left: -$height/2;
    right: auto;
  }
}
