@use '../variables' as *;

:local {
  .wrapper {
    padding: 20px $sidePaddingSmall 70px;
    border-top: 10px solid #2f9dc6;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 1070px) {
      padding: 20px $sidePadding 70px;
    }
  }

  .wish {
    font-size: 20px;
    padding-top: 21px;
  }

  .logo {
    font-size: 32px;
    text-transform: uppercase;
    color: #2f9dc6;
    text-align: right;
    width: 350px;


    img {
      max-width: 100%;
    }

    span {
      font-size: 25px;
    }
  }

  .copyright {
    font-size: 16px;
    text-align: right;
    color: #8c8b89;
  }
}
