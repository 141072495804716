@use "styles/colors.scss" as *;

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  border: 2px solid $basicEhrBlue;
  border-top-color: white;
  transform: translate(-50%, -50%);
  animation: rotate infinite linear 2s;
  z-index: 99999;

  &_normal {
    composes: loader;

    width: 20px;
    height: 20px;
  }

  &_small {
    composes: loader;

    width: 15px;
    height: 15px;
  }

  &_large {
    composes: loader;

    width: 40px;
    height: 40px;
  }

  &--static {
    position: static;
    display: inline-block;
    transform: none;
    animation: rotateStatic infinite linear 2s;
  }
}

@keyframes rotateStatic {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
