:local {
  .wrapper {
    width: 100%;
    min-height: 100%;
    background-color: #2f9dc6;
    padding: 20px;
    position: relative;
  }

  @media screen and (min-height: 700px) {
    .wrapper {
      height: 100%;
      min-height: initial;
    }
  }

  @media screen and (min-width: 580px) and (min-height: 700px) {
    .logo-wrapper {
      display: flex;
    }
  }

  .inner-wrapper {
    width: 960px;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .logo-wrapper {
    height: 10%;
    margin: 0 auto;
    width: 800px;
    max-width: 100%;
    display: none;
    align-items: center;

    & > div {
      width: 100%;
      height: 100%;
    }

    img {
      max-height: 100%;
      margin: 0 auto;
      width: initial;
      max-width: 100%;
    }
  }

  .form-wrapper {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loader-wrapper {
    position: relative;
    height: 70px;
    width: 100%;
  }

  .loader {
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      border: 2px solid white;
      border-top-color: #2f9dc6;
      transform: translate(-50%, -50%);
      animation: rotate infinite linear 2s;
    }
  }

  .reload-button {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    background: url('../../../../../images/Home-128.png') center no-repeat;
    border: none;
    background-size: cover;
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
