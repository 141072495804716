@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    i {
      vertical-align: middle;
    }
  }

  .label {
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .shape--circle {
    border-radius: 50% !important;
  }

  .shape--square {
    border-radius: 0 !important;
  }

  .checkbox {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWhJREFUeNrslTEvBEEYhu3eEaFESUnUfoJKso1/QEEhkUgEhyA0J5qLgoSotSQ20fkFSrVW5VCIhDvrmeTby2cyu25ZNPsmT7K3m51n5pvvZr0oijr+Il4h+hdRGIb2rW7oCYKgnovIIdDpg3dkD98WfSEwGYMXeIQ7ZK3ByzkMbtIFy7AN83AIvfCcuKI2B9YZloHHYUdoQic04lWVlcA3tc0omYRj6BfBlnr2BmbMyC6dl1GyBFUowb6WsIr4sjVxXz1s2h2Z9JeAGuyJ5EykqfEd9wbgCM5hyLHpJ7Agv29gRsqUGlfXTcCsXI/AHFzJ7M0EpuTZE0zDvVWudJFqhlMYhYqILmEFBpXEZB2u291QvaKSiBqwCq+wKeWqWe9dwEGWztF7ZDeD6aINxzvmaFn80aFK+by471Uq0sZx1tiPalbRp67TZ5PKruyRyW3WkqUeqgnHkCljncnkJyo+5YXoV/MhwAB5vocgA1miVQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: 2px solid #b3b3b3;
    border-radius: 50%;
    transition: border-color 0.2s ease-in, background-image 0.2s ease-in;

    &--active--blue {
      composes: checkbox;
      border-color: $basicEhrBlue;

      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAW1JREFUeNpi/P//PwM9ABMDncCoRXiBwbzjHEAsRHOLLiRZ/gBSjEDLBGntI2MgJQ7EnEA2I9UtAhrKBsQ1QOYpIHYA+uwZkOaiqkVAC9SA1DYgbgbiFiCeCZX6hewrJiQNTGRYEgikjgKxMxA3AX1SD8R/ofH1GxRf2HzESKIlpUBqNRCLAPFEkCVYEsc/uOG4iiCQt4EK/2MTB1J9QFwAFVoPxOFQH+AEGBYBDRKFhrUEEOcCDXiEHOlAajoQJ0GFrkAj/y05GdYTiNOA2A+IdwENd4JawgyNaJglH4E4kRhLcCWGZUDcAWWrA/F2oBwomLqAOAFJbzXQkjPExikLEhvk4n9AzX+AdCXQ8F9Aug6IQcHVj6ZvM1DdVHLLur9oKQaUimqx6HkPxMVkF6qgpIhebADFQImiEk1PN1D8NqkWMRJTwwIdUAakOoH4HhAbAi36RBOLoJaBgvEdqXFDskWjVfmoRVQDAAEGAG+piU7eNkuEAAAAAElFTkSuQmCC);
    }

    &--active--green {
      composes: checkbox;

      background-image: url(./green-check.png);
    }

    &--active--yellow {
      composes: checkbox;

      background-image: url(./yellow-check.png);
    }

    &--disabled {
      composes: checkbox;

      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWhJREFUeNrslTEvBEEYhu3eEaFESUnUfoJKso1/QEEhkUgEhyA0J5qLgoSotSQ20fkFSrVW5VCIhDvrmeTby2cyu25ZNPsmT7K3m51n5pvvZr0oijr+Il4h+hdRGIb2rW7oCYKgnovIIdDpg3dkD98WfSEwGYMXeIQ7ZK3ByzkMbtIFy7AN83AIvfCcuKI2B9YZloHHYUdoQic04lWVlcA3tc0omYRj6BfBlnr2BmbMyC6dl1GyBFUowb6WsIr4sjVxXz1s2h2Z9JeAGuyJ5EykqfEd9wbgCM5hyLHpJ7Agv29gRsqUGlfXTcCsXI/AHFzJ7M0EpuTZE0zDvVWudJFqhlMYhYqILmEFBpXEZB2u291QvaKSiBqwCq+wKeWqWe9dwEGWztF7ZDeD6aINxzvmaFn80aFK+by471Uq0sZx1tiPalbRp67TZ5PKruyRyW3WkqUeqgnHkCljncnkJyo+5YXoV/MhwAB5vocgA1miVQAAAABJRU5ErkJggg==) !important;
      border-color: #b3b3b3 !important;
    }
  }

  .checkbox--small {
    width: 26px;
    height: 26px;
  }

  .checkbox--large {
    width: 36px;
    height: 36px;
  }

  .active-color--green {
    border-color: #30c6a1;
  }

  .active-color--yellow {
    border-color: yellow;
  }

  .loader {
    width: 26px !important;
    height: 26px !important;
    vertical-align: middle;
  }
}
