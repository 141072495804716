@use '../../variables' as *;
:local {
  .wrapper {
    padding: 0 $sidePaddingSmall;
    margin-top: 25px;
    position: relative;
    @media screen and (min-width: 1070px) {
      padding: 0 $sidePadding;
    }
  }
}
