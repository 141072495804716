:local {
  .pointer {
    cursor: pointer;
  }

  .image {
    width: 30%;
    background-size: contain;
    height: auto;
    display: table;
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      transform: translateY(-50%);
    }
  }

  .card-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border: 1px solid #cccccc;
    width: 100%;
    position: relative;
    margin-bottom: 9px;
    padding: 10px;
    background: white;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-left: 10px;
    align-items: self-start;
  }

  .card-plan {
    padding-top: 10px;
    font-size: 18px;
    text-transform: uppercase;
    color: #575757;
    line-height: 24px;
  }

  .card-description {
    padding-top: 15px;
  }

  .card-price {
    font-size: 15px;
    color: #2f9dc6;
    line-height: 18px;
  }
}
