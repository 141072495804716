:local {
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .tabsWrapper {
    flex: 1 1 auto;
    display: flex;
    /* firefox needs this for flex-shrink to work */
    min-height: 0;
  }
}
