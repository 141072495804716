:local {
  .wrapper {
    background-color: #ffffff;
    border: 1px solid #cccfd1;
    color: #333333;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 38px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .content {
    bottom: 0;
    color: #aaaaaa;
    left: 0;
    line-height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 40px;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .arrow-zone {
    position: relative;
    cursor: pointer;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    background: #2f9dc6;
  }

  .arrow {
    border-color: white transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    height: 0;
    width: 0;
    transition: transform 0.3s;
    display: inline-block;
    vertical-align: middle;

    &:after {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    &--up {
      transform: rotate(180deg);
    }
  }

  .menu-container {
    width: 140px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    z-index: 1;
    -webkit-overflow-scrolling: auto;
    background: white;
    text-align: left;
    border: 1px solid #cccfd1;
    box-shadow: 3px 3px 0 rgba(0,0,0,0.3);
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -10px;
      display: block;
      border-top: none;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    &:before {
      border-bottom: 10px solid #cccfd1;
      bottom: 100%;
    }

    &:after {
      bottom: calc(100% - 1px);
      border-bottom: 10px solid white;
    }
  }

  .menu {
    position: relative;
    z-index: 1;
    max-height: 198px;
    overflow-y: auto;
  }

  .option {
    box-sizing: border-box;
    border: none;
    width: 100%;
    display: block;
    text-align: left;
    cursor: pointer;
    background-color: #ffffff;
    color: #666666;
    padding: 5px 15px;
    transition: all 0.1s;
    text-decoration: none;
    &:hover {
      background-color: #2f9dc6;
      color: white;
    }
    &-disabled {
      composes: option;
      cursor: not-allowed !important;
      opacity: 0.5;
      &:hover {
        background-color: #c3c3c3;
        color: white;
      }
    }
  }
}

:global {
  .chartActions-element {
    z-index: 6;
  }

  .chartActions-element.chartActions-element-attached-bottom {
    margin-top: -10px;
  }

  .chartActions-element.chartActions-element-attached-top {
    margin-top: 10px;
  }

  .chartActions-element.chartActions-element-attached-bottom {
    :local {
      .menu-container {
        &:before {
          border-top: 8px solid rgba(0, 0, 0, 0.3);
          border-bottom: none;
          top: calc(100% + 3px);
          left: 52%;
        }
        &:after {
          top: calc(100% - 1px);
          border-top: 10px solid white;
          border-bottom: none;
        }
      }
    }
  }

  .chartActions-element.chartActions-element-attached-top {
    :local {
      .menu-container {
        &:before {
          border-bottom: 10px solid #cccfd1;
          border-top: none;
          bottom: 100%;
        }
        &:after {
          bottom: calc(100% - 1px);
          border-bottom: 10px solid white;
          border-top: none;
        }
      }
    }
  }
}
