@use '../../../../../../../../../../styles/input-variables.module' as *;
@use '../../../../../../../../../../styles/inputs.module' as *;
@use '../../../../../../../../../../containers/Demographics/DemographicsForm/style.module' as *;
@use '../../../../../../../../../../styles/loader.module' as *;

:local {
  .loader-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .checkboxes {
    overflow: hidden;
    margin-top: 20px;
    display: flex;
  }

  .preferences-form {
    flex: 1;
    padding: 30px 0;
    width: 100%;
    background-color: #ffffff;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
  }

  .form-section {
    padding: 15px 25px;

     + .form-section {
      border-top: 1px solid #b3b3b3;
    }
  }

  .element-wrapper {
    margin-left: 260px;


    &__logout-time {
      composes: element-wrapper;
      margin-bottom: 10px;
    }
  }

  .input-container {
    min-height: 22px;
    display: flex;
    align-items: center;
  }

  .input-note {
    display: inline-block;
    margin-left: 12px;
    color: #2f9dc6;
    font-size: 12px;
    line-height: 14px;
    vertical-align: -1px;
  }

  .password-input {
    composes: input;
    width: 100px;
  }

  .reset-password-button {
    composes: button;
    background: #2f9dc6;
    border-color: #2f9dc6;
    color: #ffffff;
    padding: 6px 32px;
    min-width: 0;
  }

  .form-footer {
    text-align: right;
    padding: 0 25px;
  }

  .form-button {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
    font-size: 15px;
    line-height: 15px;
    padding: 14px 30px;
    box-sizing: border-box;
    border: none;

    &__cancel {
      composes: form-button;
      background-color: #ffffff;
      border: 1px solid #9a9a9a;
      color: #000000;
    }

    &__save {
      composes: form-button;
      background-color: #2f9dc6;
      border: 1px solid #2f9dc6;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}

:global {
  .Select-placeholder,
  .Select-value-label {
    text-align: left;
  }
}
