@use 'src/styles/inputs.module.scss' as *;

:local {
  .block-wrapper {
    display: flex;
    flex-direction: column;
  }

  .select-wrapper {
    composes: small-wrapper;
  }

  .line-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 925px) {
      flex-direction: row;
    }
  }

  .city-wrapper {
    composes: select-wrapper;
  }

  .state-wrapper {
    margin-bottom: 18px !important;
    composes: select-wrapper;

    .state-wrapper__label {
      top: 0;
      transform: translateY(50%);
    }
  }

  .zip-wrapper {
    composes: small-wrapper;
    float: left;
  }
}
