:local {
  .table__head {
    button:hover,
    button {
      color: white;

      & *:hover,
      & * {
        border-color: white;
      }
      & *:after,
      & *:before {
        background: white;
      }
    }
  }
  .row {
    position: relative;
    h3 {
      text-align: left;
    }

    .upload-wrapper {
      display: flex;
      align-items: center;
      column-gap: 15px;

      button {
        min-width: 40px;
      }
    }

    input {
      height: 41px!important
    }
  }
  .row__last {
    margin-bottom: 30px;
  }
}

:global {
  .datepicker-element {
    z-index: 1000;
  }
}

