:local {
  $sidebarWidth: 80px;

  .sidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $sidebarWidth;
    z-index: 5;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    background-color: #2f9dc6;
    transform: translate(-$sidebarWidth, 0);
    transition: transform 0.3s;

    @media screen and (max-width: 1366px) {
      transform: translate(-$sidebarWidth, 0);
      &--visible {
        composes: sidebar;
        transform: translate(0,0);
      }
    }
    &--visible {
      composes: sidebar;
      transform: translate(0,0);
    }
    @media screen and (min-width: 1000px) {
      transform: translate(0,0);
      &--visible {
        transform: translate(0, 0);
      }
    }
  }
}
