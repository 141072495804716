:local {
  .text {
    margin-left: 10px;
    color: #c3c3c3;
  }

  .icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #c3c3c3;
    transition: border-color 0.2s;


    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 66%;
      height: 2px;
      background: #c3c3c3;
      transform: translate(-50%, -50%);
      transition: background 0.2s;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
