:local {

  .no-known-drug-allergies {
    font-weight: bold;
  }

  .allergy {
    cursor: pointer;
  }

  .reaction + .reaction {
    margin-top: 8px;
  }
}
