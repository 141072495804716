@use "src/styles/colors.scss" as *;

:local {
  .title {
    display: block;
    font-weight: 400;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
}
