:local {
  .arrows {
    position: relative;
    display: inline-block;
    width: 7.017px;
    margin-left: 5px;
    &:after,
    &:before {
      position: absolute;
      left: 0;
      display: inline-block;
      border-right: 1px solid rgba(255,255,255,0.3);
      border-bottom: 1px solid rgba(255,255,255,0.3);
      width: 4px;
      height: 4px;
      content: '';
    }
    &:before {
      transform: rotate(-135deg);
      top: -4px;
    }

    &:after {
      transform: rotate(45deg);
      bottom: -4px;
    }

    &--down {
      &:after {
        border-color: rgba(255,255,255,1);
      }
    }

    &--up {
      &:before {
        border-color: rgba(255,255,255,1);
      }
    }
  }
}
