:local {
  .wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    &-responsive-height {
      composes: wrapper;

      height: 100%;
    }
  }
}
