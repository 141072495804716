:local {
  .title {
    width: 238px;
    color: #2f9dc6;
    font-size: 15px;
    line-height: 34px;
    font-weight: 200;
    text-align: right;
    box-sizing: border-box;
    min-height: 34px;
  }

  .list {
    padding-left: 0;
    margin-top: 5px;
  }
}
