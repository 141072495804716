@use "src/styles/colors.scss" as *;

:local {
  .dropzone {
    width: auto;
    height: auto;
    border: none;
    position: relative;
    display: inline-block;

    .loader {
      width: 20px;
      height: 20px;
      right: 0;
      left: initial;
      border-color: white;
      border-top-color: $basicEhrBlue;
    }
  }
}
