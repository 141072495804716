@use "src/styles/colors.scss" as *;

.cancel {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0);
  background-size: contain;
  outline: none !important;
  background: white url('../../images/close.png') center no-repeat;
  background-size: contain;

  &--disabled {
    composes: cancel;

    opacity: .5;
    cursor: default;
  }
}

.button-large {
  width: 40px;
  height: 40px;
}
