@use '../../variables' as *;
:local {
  .wrapper {
    padding: 0 $sidePaddingSmall;
    margin-top: 18px;
    margin-bottom: 30px;

    @media screen and (min-width: 1070px) {
      padding: 0 $sidePadding;
    }
  }

  .textarea {
    border: 1px solid #b3b3b3;
    padding: 15px 10px;
    width: 100%;
    resize: none;
    font-style: italic;
    font-size: 16px;
    color: #16495c;

    &--disabled {
      background: none;
      border: none;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
}
