:local {
  .wrapper {
    display: inline-block;
    vertical-align: middle;
    font-size: 21px;
    text-transform: uppercase;
    color: #2f9dc6;
    font-weight: 400;
    padding-left: 42px;
    position: relative;
    margin: 0;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 24px;
      left: 16px;
      top: 50%;
      margin-top: -12px;
      background: url(./images/title-icon.png) 0 0 no-repeat;
    }
  }

  .label {
    display: inline-block;
    vertical-align: middle;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 34px;
  }

  .button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #2f9dc6;
    cursor: pointer;
    transition: border-color 0.2s;

    &:hover {
      border-color: #35738a;
      &:before,
      &:after {
        background: #35738a;
      }
    }
  }

  .toggle-button {
    composes: button;
    margin: 0 10px;

    &--disabled {
      border-color: #8a8a8a;
      .check-icon {
        fill: #8a8a8a;
      }
    }

    &:hover {
      .check-icon {
        fill: #35738a;
      }
    }
  }

  .check-icon {
    fill: #54a2bf;
    transform: scale(0.85);
    transition: fill 0.2s;
  }
}
