@use "src/styles/mixins" as *;

:local {
  .chart {
    cursor: pointer;

    td {
      padding: 5px;
      vertical-align: top;
    }

    &:nth-child(even) {
      background-color: rgb(242.75, 242.75, 242.75);
    }

    &:hover {
      background: #d5ebf4;
    }
  }

  .cc {
    max-width: 180px;
    text-align: left;
    @include ellipsis()
  }
}
