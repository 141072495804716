:global {
  .datepicker-element {
    z-index: 1;
    background: white;
  }

  .rdtPicker {
    display: flex;
  }
}

:local {
  .datepicker {
    border: 1px solid #cccfd1;
    display: flex!important;
  }

  .input-wrapper {
    position: relative;
    display: flex;
  }

  .input {
    width: 100px;
    padding: 8px 12px;
    height: 32px;
    box-sizing: border-box;
    max-width: 100%;
    outline: none;
    border: 1px solid #cccfd1;
  }
}

