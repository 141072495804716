@use "src/styles/colors.scss" as *;

:local {
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 7px 0;
    // height: 100%;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 0;
  }

  .title {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 18px;
    color: #404040;
    margin-bottom: 7px;
  }

  @media screen and (min-height: 769px) {
    .section {
      padding: 15px 0;
    }

    .title {
      margin-bottom: 18px;
    }
  }
}
