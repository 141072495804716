:local {
  .encounter {
    font-size: 14px;
    &--active {
      background: darken(#f0f0f0, 5%) !important;
    }

    &-date {
      font-size: 13px;
    }
  }

  .name {
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dx,
  .dob {
    display: block;
    text-align: center;
    font-size: 0.9em;
    color: #2f9dc6;
  }

  .note-type {
    & > p {
      margin: 0;
    }
  }

  .select-user-date {
    margin-left: 13px;
  }

  .locked-icon {
    vertical-align: middle;
    margin-right: 0;
    width: 19px;
    display: initial;
    position: absolute;
    left: 0;
  }

  .loader {
    position: static;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    content: '';
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #2f9dc6;
    border-top-color: white;
    animation: rotate infinite linear 2s;
    vertical-align: middle;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #f0f0f0;
    opacity: 0.5;
  }

  .error {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border: none;
    background: url('../../../images/exclamation-128.png') center no-repeat;
    background-size: cover;
    vertical-align: middle;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
