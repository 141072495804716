:local {
  .container {
    width: 100%;
  }

  .title {
    margin-bottom: 8px;
    text-transform: uppercase;
    display: block;
  }

  .loader-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .image-container {
    width: 70%;
    float: left;
    position: relative;
  }

  .controls {
    width: 30%;
    float: left;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    max-width: 100%;

    &--loading {
      opacity: 0.5;
      background: white;
    }
  }

  .info-popup-container {
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -8px;
  }

  .button {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    font-size: 10px;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      margin: auto auto 5px;
      border-radius: 50%;
      background-color: #2f9dc6;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  .file-button {
    composes: button;

    &:before {
      background-image: url(./file.png);
    }

    &:hover {
      background: transparent;
      color: #404040;
    }
  }

  .error {
    color: #ff0000;
    font-size: 12px;
    text-align: left;
  }
}
