:local {
  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    padding: 100px 0;
  }

  .dot {
    width: 20px;
    height: 20px;
    background-color: darken(#2f9dc6, 20%);
    border-radius: 50%;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out, background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    &--filled {
      background: white;
      box-shadow: 0 0 15px white;
    }

    &--error {
      background: darken(#ffbfbf, 20%);
      box-shadow: 0 0 15px darken(#ffbfbf, 20%);
    }
  }

  @media screen and (min-width: 500px) {
    .dot {
      width: 30px;
      height: 30px;
    }
  }
}
