:local {
  .settings-page {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tabs-wrapper {
    flex: 1 1;
    min-height: 0;
    display: flex;
  }
}
