:local {
  tr.wrapper {
    cursor: pointer;
    display: flex;
    &:hover {
      background: #d5ebf4;
    }

    &--inactive {
      opacity: 0.7;
    }
  }
}
