:local {
  .variable {
    color: #2f9dc6;
    border: none;
    background: none;
    // text-transform: uppercase;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .subelements-wrapper {
    //width: 300px;
    //padding-left: 10px;
    //margin-top: 30px;
  }
}
