:local {
  .wrapper {
    display: inline-block;
    padding: 2px 0;
    width: 60%;
    border-bottom: 2px solid  #2f9dc6;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background: url(./edit.png) 0 50% no-repeat;
      background-size: contain;
    }

    @media screen and (max-width: 767px) {
      width: 40%;
    }
  }

  .input {
    border: none;
    outline: none;
    font-weight: 300;
    font-size: 14px;
    margin: 4px 0;
    width: calc(100% - 40px);
    box-sizing: border-box;
  }
}
