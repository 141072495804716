.control-button {
  width: 35px !important;
  height: 35px !important;
  background-color: transparent;

  &-wrapper {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.save-button-wrapper {
  right: 40px;
}

.wrapper {
  position: relative;
  padding-right: 80px;

  .input {
    width: 100%;
  }
}


