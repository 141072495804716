@use '../../../../../../../../../../styles/loader';

:local {
  .wrapper {
    position: relative;
    padding: 15px 15px 0;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;

    // flex-shrink not working in firefox without min-height: 0
    min-height: 0;
    height: 100%;
    &--loading {
      composes: loader;
    }
  }

  @media screen and (min-width: 1025px) {
    .wrapper {
      padding: 15px 15px 0;
    }
  }
}