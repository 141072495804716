@use 'src/styles/input-variables.module.scss' as *;
@use 'src/styles/inputs.module.scss' as *;

:local {
  .active-buttons-wrapper {
    padding-left: 180px;
  }
  .form {
    width: 100%;
    clear: both;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
  }

  .patientId {
    color: gray;
  }

  .element-group {

    &__base-inputs {
      composes: element-group;
      width: 50%;
      margin-right: 0;
      margin-top: $marginMainTop;
    }

    &__image-loader {
      composes: element-group;
      position: absolute;
      top: 0;
      right: 0;
    }

    &__notes {
      position: absolute;
      top: 270px;
      right: 0;
      margin-right: 60px;

      p {
        margin-bottom: 3px;
        text-transform: uppercase;
      }

      textarea {
        width: 20em;
        height: 120px;
        resize: none;
        padding: 2px 10px;
        border: 1px solid #b3b3b3;
      }
    }
  }

  .controls-group {
    composes: element-group;
    padding-bottom: 20px;
    text-align: right;
  }

  .element-wrapper {
    select {
      width: 100%;
    }
  }

  .dob-wrapper {
    composes: element-wrapper;

    :global {
      .wrapper {
        position: relative;
        margin-right: $heightElement;

        > div:first-child {
          width: 100%;

          > input {
            width: 100%;
            height: $heightElement;
            font-size: $fontSize;
            padding: 2px 10px;
            border: 1px solid $borderColor;
          }
        }

        // > nth-child(2) {
        //     position: absolute;
        //     height: $heightElement;
        //     width: $heightElement;
        // }

      }
    }
  }

  .text-wrapper {
    composes: element-wrapper;
    height: $heightElement;

    .text-age {
      & > span {
        margin: 0 !important;
      }
    }

    .text-age::before {
      content: '( ';
    }

    .text-age::after {
      content: ' )';
    }
  }

  .base-wrapper {
    flex-direction: row;
    display: flex;
  }

  .phone-wrapper {
    composes: element-wrapper;
    width: auto;
    composes: base-wrapper;
  }

  .phone-input-container {
    width: 210px;
    float: left;
  }

  .phone-number-type {
    margin-left: 20px;
  }

  .email-wrapper {
    composes: element-wrapper;
    clear: both;
    composes: base-wrapper;
    composes: element-group;
  }

  .text {
    top: center;
  }

  .label-required {
    composes: label;

    &:after {
      content: '*';
    }
  }

  .save {
    composes: button;
    position: relative;
    margin-left: 20px;
    margin-right: 0;
    background-color: $colorBlue;
    border-color: $colorBlue;
    color: white;
    transition: opacity 0.3s;

    &[disabled] {
      opacity: 0.3;
    }
  }

  .title {
    composes: label;
    top: center;
    right: 0;
    left: 0;
    padding: 0;
    padding-left: 44px;
  }

  .gender-wrapper {
    composes: element-wrapper;

     > .label {
      transform: none;
      top: 0;
    }

    .radio-wrapper:last-child {
      margin-top: 6px;
    }
  }
}
