@use "src/styles/inputs.module.scss" as *;
@use "src/styles/basic" as *;

:local {
  .container {
    width: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 12px;
    flex-direction: column;

    div {
      width: 100%;
      padding: 4px;
    }
  }

  .form-password {
    height: 48px;
    display: flex;
    position: relative;
  }

  .password-container {
    width: 60%;

    & > span {
      width: 60%;
      margin-top: 2px;
    }
  }

  .password {
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0,0%,80%);
    padding: 2px 8px;
  }

  .password:focus {
    border-color: #3684FF;
  }

  .submit-password {
    width: 40%;
    composes: generic-button;
    height: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .access-account {
    composes: generic-button;
    font-size: 1rem;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
