:local {
  .wrapper {
    position: absolute;
    width: 100%;
    z-index: 5;
    padding: 5px 15px;
    font-size: 0;
    background: white;
    color: #849ba5;
    box-shadow: 0 -2px 3px rgba(1, 1, 1, 0.3);
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    display: flex;
    bottom: 0;
    overflow: hidden;

    &--hidden {
      composes: wrapper;

      display: none;
    }
  }

  .copyrights {
    font-size: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    vertical-align: middle;
    text-align: center;
  }

  .footerLinks {
    a {
      font-size: 12px;
      color: #575757;
      text-decoration: none;
    }

    a + a {
      margin-left: 20px;
    }
  }
}