:local {
  .page-nav {
    text-align: center;
    padding: 10px 0;
  }

  .nav-button {
    display: inline-block;
    text-decoration: none;
    padding: 7px 20px;
    font-size: 14px;
    border-radius: 48px;
    border: 1px solid #60b3d2;
    background-color: transparent;
    color: #60b3d2;
    text-align: center;
    cursor: pointer;
    outline: none;

    &:hover {
      background: #60b3d2;
      color: white;
    }

    &:active {
      background: #2f9dc6;
      color: White;
    }

    &--prev {
      margin-right: 10px;
    }

    &--next {
      margin-left: 10px;
    }

    &--disabled {
      border-color: grey !important;
      color: grey !important;
      cursor: default;
      background: none !important;
    }
  }

  .page-num {
    font-size: 15px;
    color: #2f9dc6;
  }
}
