:local {
  .wrapper {
    vertical-align: middle;
    width: 45px;
    height: 45px;
    margin-right: 15px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    display: none;

    @media screen and (min-height: 769px) and (min-width: 490px) {
      display: inline-block;
      width: 65px;
      height: 65px;
      margin-right: 20px;
    }
  }
}
