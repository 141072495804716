:local {
  .wrapper {
    white-space: nowrap;
    padding: 3px 0 0;
    background: #2f9dc6;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: auto;
    box-sizing: border-box;
  }

  .list {
    display: table;
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;

    &--single {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1025px) {
    padding: 10px 0 0;
  }

  .wrapper-inner {
    composes: wrapper;
    padding-top: 0;
    background-color: #f0f0f0;
  }

  .wrapper-inner .list {
    padding: 0;
  }
}
