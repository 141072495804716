@use '../../../../../styles/rounded-cross' as *;

:local {
  .search-input {
    position: relative;
    padding-left: 20px;
    padding-right: 40px;
    border-bottom: 1px solid  #44a7cc;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 15px;
      top: 0;
      bottom: 0;
      left: 0;
      background: url(./edit-icon.png) 0 50% no-repeat;
      background-size: contain;
    }
  }

  .input {
    width: 100%;
    padding: 6px 0;
    outline: none;
    border: none;
    font-size: 12px;
  }

  .cancel {
    @include roundedCross(20px, 20px, 100%, #2f9dc6);
    background: none;
    position: absolute;
    right: 0;
    top: 5px;
    bottom: 0;

    &:focus {
      outline: none;
    }
  }
}
