:global {
  .search-results-element {
    width: 400px;
  }
}

:local {
  .wrapper {
    border: 1px solid #cccccc;
  }

  .loader {
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    bottom: 0;
    text-indent: -9999em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    background:    -moz-linear-gradient(left, black 10%, rgba(0,0,0, 0) 42%);
    background: -webkit-linear-gradient(left, black 10%, rgba(0,0,0, 0) 42%);
    background:      -o-linear-gradient(left, black 10%, rgba(0,0,0, 0) 42%);
    background:     -ms-linear-gradient(left, black 10%, rgba(0,0,0, 0) 42%);
    background:         linear-gradient(to right, black 10%, rgba(0,0,0, 0) 42%);
    -webkit-animation: load3 1.4s infinite linear;
            animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
            transform: translateZ(0);

    &:before {
      content: '';

      width: 50%;
      height: 50%;
      background: black;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      background: white;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  .underline {
    position: absolute;
    height: 1px;
    background-color: #2f9dc6;
    left: 38px;
    bottom: 10px;
    right: 10px;
  }

  .no-input {
    pointer-events: none;
  }

  .input {
    // padding: 12px 0;
    width: calc(100% - 38px);
    border: none;
    outline: none;
    background: none;
    float: right;
    height: 40px;
    padding: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    resize: none;
    display: block;
    position: relative;
    z-index: 10;

    &-wrapper {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding-right: 10px;

      &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        background: url('../../../images/input-icon.png') 50% 50% no-repeat;
        transform: translate(0, -50%);
        background-size: contain;
        z-index: 1;
      }

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
  }
}
