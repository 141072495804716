@use "src/styles/loader" as *;

:local {
  .wrapper {
    padding: 24px;
    white-space: normal;
  }

  .close {
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    & > div {
      border-color: #333333;
      &:before,
      &:after {
        background: #333333;
      }
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .loader {
    composes: loader;
  }

  .cross {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
