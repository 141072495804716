@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // border: 1px solid $basicEhrBlue;
    border: none;
    background: url(../../images/edit.svg) center no-repeat;
    background-size: cover;
    transition: background-color 0.2s ease-in;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
