$basicEhrBlue: #2f9dc6;
$lightBasic: #2f9dc645;
$drChronoGreen: #309549;
$formErrors: #ff0000;
$basicGreen: #30c6a1;

:export {
  basicGreen: $basicGreen;
  basicEhr: $basicEhrBlue;
  lightBasic: $lightBasic;
}
