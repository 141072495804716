@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
    min-height: 60px;
    background: white;
    border: 1px solid #cccccc;
    position: relative;
    padding-top: 43px;
  }

  .subelements-wrapper {
    padding: 9px 15px;
    max-height: 250px;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
  }

  .loading {
    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 1;
    }
  }

  .no-subelements {
    text-align: center;
    padding: 10px;
  }


  .input {
    padding: 12px 35px 12px 38px;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #cccccc;
    box-sizing: border-box;
    background: none;


    &-wrapper {
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        display: block;
        width: 20px;
        height: 20px;
        background: url(../../images/input-icon.png) 50% 50% no-repeat;
        transform: translate(0, -50%);
        background-size: contain;
        z-index: 1;
      }
    }
  }
}

:global {
  .elementsViewItems-subelements-element-attached-bottom {
    :local {
      .wrapper {
        padding-top: 0;
        padding-bottom: 43px;
      }
      .input-wrapper {
        top: initial;
        bottom: 0;
        .input {
          border-top: 1px solid #cccccc;
          border-bottom: none;
        }
      }
    }
  }
}
