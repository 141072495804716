:local {
  .medication {
    cursor: pointer;

    .medication__name {
      width: 30%
    }

    .medication__description {
      width: 40%
    }

    .medication__diagnosis {
      width: 30%
    }

    .diagnosis + .diagnosis {
      margin-top: 8px;
    }
  }
}
