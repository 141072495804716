@use  "src/styles/basic" as *;

:local {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .proceed-button {
    composes: generic-button;
    margin-bottom: 20px;
  }
}