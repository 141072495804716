:local {
  .sso {
    margin-bottom: 50px;

    &__btn {
      height: 50px;
      width: 150px;
      text-align: center;
      border: none;
      font-size: 16px;
      color: white;
      background-color: transparent;
      outline: none;
    }
  }
}