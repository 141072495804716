:local {
  .keyboard {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    max-width: 500px;
  }
  .button-wrapper {
    width: 33.333333%;
    text-align: center;
    color: white;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    background: none;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    &:hover {
      background-color: darken(#2f9dc6, 20%);
    }

    &--pressed,
    &:active {
      background-color: darken(#2f9dc6, 25%);
    }
  }

  .arrow {
    vertical-align: bottom;
  }
}
