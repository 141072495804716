$fontSize: 12px;

$widthSmallInput: 210px;
$heightElement: 28px;
$heightRadioButton: $heightElement - 2px;
$heightSmallCircle: ($heightRadioButton - 4px) / 2;

$marginMainLeft: 180px;
$marginMainRight: 60px;
$marginMainTop: 14px;
$marginGroupTop: 16px;

$errorColor: #ff0000;
$borderColor: #b3b3b3;
$colorBlue: #2f9dc6;
$colorWhite: #fff;
