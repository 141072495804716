:local {
  .plan-data {
    vertical-align: top;
    width: 55%;
    margin-right: 15px;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
    overflow-x: hidden;
  }
}
