@use "src/styles/colors.scss" as *;

:local {
  .modal-window {
    &-content {
      max-width: 800px;
      height: 80%;
      position: absolute;
      left: 50%;
      top: 50%;
      background: white;
      transform: translate(-50%, -50%);
      flex-shrink: 1;
    }

    &-data {
      padding: 10px 20px;
      overflow: auto;
    }

    &-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &-controls {
      padding: 15px 0 20px;
      font-size: 0.85em;
      text-align: center;
      flex-shrink: 0;
    }
  }
}
