@use  "src/styles/justify.scss" as *;

:local {
  .content {
    composes: justify;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #baddea;
  }

  .title {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    text-transform: uppercase;
    color: #2f9dc6;
    font-weight: 400;
    padding-left: 42px;
    position: relative;
    margin: 11px 0;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 24px;
      left: 16px;
      top: 50%;
      margin-top: -12px;
      background: url(./images/title-icon.png) 0 0 no-repeat;
    }
  }
}
