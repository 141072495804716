:local {
  .checkbox-wrapper {
    padding-left: 29px;
    position: relative;
  }

  .label {
    font-size: 12px;
    cursor: pointer;
    user-select: none;
  }

  .checkbox {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #b3b3b3;
    background-color: #fff;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQVJREFUeNpi/P//PwMtABMDjcCowXDAgswxmHecHDPYgTgNiO0vJFmGUMvFakC8Foj7gfgITheTCMKBuBeIpYE4HogXURrGIMc0AvFiIJYE4lR0Q8lxMR8QTwPiaCi/HIjnEJMqJIC4BYg9sKiVAeJVSIbOAOIeYpMbKBKqgXgNEJcCMRtUXAqIlwKxO5S/Fyr/j1iDQd68A8TcQNwFxAuA2AaI5wOxHVTNEyDOBeIvpGSQw0AMSovXoPxIIN4KxG5IaqqA+Do5Oe8i1PBLSBEGA0uhqYHsLA1yUSAQn0ASewrEbdQoK+5BMwHM8NlIQURxIfQIavh8aEQSDRhHaxCaGwwQYABkcjJgHtGUaQAAAABJRU5ErkJggg==);
      display: none;
    }

    &--active {
      composes: checkbox;

      &:after {
        display: block;
      }
    }
  }
}
