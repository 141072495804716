:local {
  .wrapper {
    margin-top: 60px;
  }

  .container {
    table tbody {
      background: white;
    }
  }
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }

  .title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    color: #404040;
  }

  .button-wrapper {
    display: inline-block;
    position: relative;

    &_with-overlay {
      composes: button-wrapper;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }
    }
  }
}
