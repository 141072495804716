@use "src/styles/colors.scss" as *;

:local {
  .wrapper {
  }

  .image {
    display: block;
    width: 100%;
  }
}
