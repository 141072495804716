:local {
  .wrapper {
    text-align: justify;
    font-size: 0;
    display: flex;
    width: 100%;
    align-items: center;

    &__settings {
      composes: wrapper;
      align-items: flex-start;
      flex-direction: column;

      // ie11 space fix
      overflow: hidden;

      .links {
        display: block;
        flex: none;
      }
    }
  }

  .links {
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .link {
    display: inline-block;
    padding: 10px 15px 16px;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
    background-color: #dedede;
    color: #404040;

    &:hover {
      background-color: #cacaca;
    }

    .hx-abbr {
      text-transform: none;
    }

    &--active {
      background-color: white !important;
    }
  }
}
