:local {
  .fixed-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #2f9dc6;
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .cell-wrapper {
      position: relative;
    }



    & > * {
      float: left;
      text-align: center;
      /*padding: 15px;*/
      padding: 8px 3px;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    &:after {
      clear: both;
    }

  }
}
