:local {
  .wrapper {
    position: relative;
    /* min-height: 600px; */
    padding: 5px 15px 0;
    background-color: white;
    box-sizing: border-box;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 0;
    height: 100%;
    padding: 0;
    padding-top: 80px;
  }
}
