:local {
  .wrapper {
    height: 100%;
    width: 80px;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
  }

  //@media only screen
  //and (min-device-width : 768px)
  //and (max-device-width : 1024px)
  //and (orientation : landscape) {
  //  .step {
  //    height: 85px !important;
  //  }
  //}

  .step {
    display: block;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    //min-height: 65px;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    color: #333333;
    position: relative;
    margin-bottom: 5px;

    &--active {
      background: #ffffff;

      &:hover .border {
        display: none;
      }
    }

    &:hover .border {
      opacity: 1;
    }

    &--no-link {
      &:hover {
        background: initial !important;
        cursor: default;
      }
    }
  }

  .label {
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 3px;
    text-align: center;
    position: absolute;
  }
}
