:local {
  .btn {
    height: 28px;
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: rgb(245, 245, 245);
    outline: none;
    border: 1px solid #bbb;
  }

  .show-btn {
    color: #399;
  }

  .hide-btn {
    color: #c33;
  }
}