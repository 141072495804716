@use  "src/styles/basic" as *;

:local {
  .item {
    cursor: pointer;
  }

  .modal-content {
    max-height: 300px;
    overflow: auto;
  }

  .back-button {
    composes: generic-button;

    margin-top: 15px;
  }

  .provider-name {
    font-size: large;
    margin-left: 5px;
  }

  .checked-container {
    display: flex;
    line-height: 50px;
  }
}
