@use "src/styles/colors.scss" as *;

:local {
  .title {
    margin-bottom: 8px;
    text-transform: uppercase;
    display: block;
  }

  .wrapper {
    position: relative;
    &:before {
      content: '';
      width: 22px;
      height: 22px;
      background: url('../../images/input-icon.png') 0 0 no-repeat;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      pointer-events: none;
    }
  }

  .pin-wrapper {
    display: inline-block;
  }

  .input {
    resize: none;
    width: 100%;
    border: 1px solid #cccfd1;
    padding: 10px 12px 12px 37px;
    font-size: 1em;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
}
