:local {
  .result-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background .2s;
    &:hover {
      background-color: #d5ebf4;
    }

    &-active {
      composes: result-item;
      background-color: #d5ebf4;
    }
  }

  .image {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .info {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 72px);
  }

  .fullname {
    margin: 0;
    font-size: .9em;
  }

  .dob {
    margin: 0;
    text-transform: uppercase;
    font-size: .7em;
    color: #2f9dc6;
  }
}
