:local {
  .wrapper {
    cursor: pointer;
    height: 48px;

    &--active {
      background-color: #d5ebf4 !important;
    }

    &--current {
      outline: 1px solid #2f9dc6;
      outline-offset: -1px;
    }
  }

  .role-select-wrapper {
    margin: 0 auto;
    display: inline-block;
    width: 80%;
  }
}
