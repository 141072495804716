@use "src/styles/colors.scss" as *;

:local {
  $side: 30px;
  $popupBgColor: #fff;
  $tailHeight: 10px;

  .help-icon {
    width: $side;
    height: $side;
    display: table;
    border-radius: 50%;
    background-color: #659fb4;
    color: #fff;
    font-weight: bold;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;

    &-small {
      composes: help-icon;

      width: $side/1.5;
      height: $side/1.5;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .popup {
    width: 150px;
    background-color: $popupBgColor;
    position: absolute;
    z-index: 10;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0,0,0,.3);
    visibility: hidden;
    transition: all .3s ease;
    opacity: 0;

    &-small-icon {
      composes: popup;

      margin-top: $side + 5px;
    }

    &-visible {
      composes: popup;

      visibility: visible;
      opacity: 1;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
    }

    &:before {
      z-index: 2;
    }

    &:after {
      z-index: 1;
    }
  }
}
