@use "src/styles/colors.scss" as *;
@use "src/styles/loader" as *;

:local {
  $inputHeight: 35px;
  $largeScreenInputHeight: 47px;
  $labelFontSize: 14px;
  $largeScreenLabelFontSize: 19px;
  $rowPaddingBottom: 20px;
  $largeScreenRowPaddingBottom: 17px;
  $submitVerticalPadding: 11px;
  $largeScreenSubmitVerticalPadding: 17px;

  .recoveryLinkWrapper {
    text-align: right;
  }

  .recoveryLink {
    color: white;
    font-size: 12px;
    text-decoration: none;
  }

  .step {
    display: none;
    flex: 1 1 auto;
    &--visible {
      display: flex;
    }
  }
  .label {
    color: white;
    margin-top: 0;
    font-size: $labelFontSize;
    font-weight: 100;
    margin-bottom: 8px;
  }

  .error-message {
    margin: 0;
    color: #ffbfbf;
    font-size: 1.3em;
  }

  .success-message {
    margin: 0;
    font-size: 1.3em;
    color: lightgreen;
  }

  .wrapper {
    // height: 100%;
    // width: 100%;
    // position: relative;
    display: flex;
    width: 100%;
    &:before {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 9995;
      background: rgba(0, 0, 0, 0.6);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      z-index: 9999;
      border: 3px solid white;
      border-top-color: #2f9dc6;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
    }
  }

  .authenticating {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
    }

    &:after {
      animation: rotate infinite linear 2s;
    }
  }

  .form {
    position: relative;
    /* width: 100%; */
    /* height: 100%; */
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    //justify-content: space-between;

    :global {
      .Select.is-disabled > .Select-control {
        background: #267e9e;
      }
    }
  }

  .input, .hosted-field {
    display: block;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    background: #267e9e;
    outline: none;
    color: white;
    font-size: $labelFontSize;
    height: $inputHeight;
    border-radius: 0;
    &::placeholder {
      color: lightgray;
    }
  }

  .row {
    position: relative;
    margin: 5px 0;
    padding-bottom: $rowPaddingBottom;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .submit {
    padding: $submitVerticalPadding 40px;
    text-align: center;
    background-color: transparent;
    color: white;
    font-size: 20px;
    border: 1px solid white;
    outline: none;
    transition: all 0.3s;
    width: 100%;
    display: block;

    &:not(:disabled):hover {
      background-color: white;
      color: #2f9dc6;
    }
  }

  .drchrono-sign-in {
    composes: submit;

    margin-top: 15px;
    text-decoration: none;
    background-color: #309549;
    border-color: #309549;

    &:not(:disabled):hover {
      background-color: white;
      color: #309549;
    }
  }

  .drchrono-sign-in-attached {
    composes: drchrono-sign-in;

    background-color: #ccc;
    border-color: white;
    color: white;
  }

  .selectWrapper {
    font-size: 20px;

    :global {
      .Select-menu {
        font-size: $labelFontSize;
      }
      .Select-control {
        background: #267e9e;
        border: none;
        height: $inputHeight;
      }

      .Select-input > input {
        padding: 8px 0;
      }

      .Select-input {
        height: $inputHeight;
        font-size: $labelFontSize;
      }

      .Select-arrow-zone {
        width: 0;
      }
      .Select-control .Select-value .Select-value-label {
        color: white !important;
      }
      .Select-input {
        color: white !important;
      }

      .Select-placeholder,
      .Select-control .Select-value {
        line-height: $inputHeight;
        font-size: $labelFontSize;
        color: white !important;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .label {
      font-size: $largeScreenLabelFontSize;
    }

    .recoveryLink {
      font-size: 14px;
    }

    .input, .hosted-field {
      font-size: $largeScreenLabelFontSize;
      height: $largeScreenInputHeight;
    }

    .row {
      padding-bottom: $largeScreenRowPaddingBottom;
    }

    .submit {
      padding: $largeScreenSubmitVerticalPadding 40px;
    }

    .selectWrapper {
      font-size: 20px;

      :global {
        .Select-menu {
          font-size: $largeScreenLabelFontSize;
        }
        .Select-control {
          height: $largeScreenInputHeight;
        }

        .Select-input > input {
          padding: 12px 0;
        }

        .Select-input {
          height: $largeScreenInputHeight;
          font-size: $largeScreenLabelFontSize;
        }

        .Select-placeholder,
        .Select-control .Select-value {
          line-height: $largeScreenInputHeight;
          font-size: $largeScreenLabelFontSize;
        }
      }
    }

    .actions {
      margin-top: 20px;
    }
  }

  .auth-form-wrapper {
    position: relative;
    height: 100vh;
  }

  .drchrono-auth-form-wrapper {
    height: 100vh;
    width: 100vw;
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.submit-button-wrapper {
  position: relative;
  width: 100%;
}

.loading {
  composes: submit-button-wrapper;
  composes: loader;
  &:after {
    content: '';
    width: 16px;
    height: 16px;
    left: 20px;
    border-color: white;
    border-top-color: #70bbd8;
    transform: translate(0, -50%);
  }
}
