:local {
  .root {
    height: 100vh;
  }

  .wrapper {
    min-height: 100vh;
  }

  .page {
    position: relative;
    overflow: scroll;
    height: calc(100% - 49px);
  }
}
