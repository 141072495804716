@use '../variables' as *;

:local {
  .logo-container {
    width: 300px;
    max-height: 150px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 30px;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  .wrapper {
    padding-top: 70px;
    padding-bottom: 30px;
    position: relative;
    line-height: 1.2;
  }

  .content {
    padding: 0 $sidePaddingSmall;

    @media screen and (min-width: 1070px) {
      padding: 0 $sidePadding;
    }
  }

  .practice-contact-info {
    margin-bottom: 30px;
  }

  .patient-doctor-info {
    padding: 0 $sidePaddingSmall;
    margin-top: 30px;
    overflow: hidden;
    // display: flex;
    justify-content: space-between;
    z-index: 1;
    @media screen and (min-width: 1070px) {
      padding: 0 $sidePadding;
    }
  }

  .patient-doctor-info-container {
    position: relative;
    min-height: 150px;
    overflow: hidden;
  }

  .patient-name {
    font-size: 32px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .patient-info {
    text-transform: uppercase;
    margin-top: 10px;
    position: absolute;
    bottom: 70px;
    left: 0;
    width: 50%;

    span + span {
      margin-left: 10px;
    }
  }

  .practice-contact-info {
    text-align: right;
  }

  .phones-container {
    text-align: right;
  }

  .practice-address {
    margin-bottom: 7px;
  }

  .practice-phone {
    // display: inline-block;
    vertical-align: top;
    font-weight: bold;

    // & + .practice-phone {
    //   padding-left: 15px;
    // }
  }

  .image {
    width: 129px;
    height: 129px;
    border-radius: 50%;
    border: 2px solid #969696;
    margin-right: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &-placeholder {
      height: 129px;
      width: 0;
    }
  }

  .image-container {
    display: flex;
    align-items: flex-end;
    color: white;
    position: absolute;
    top: -65px;
    left: 80px;

    p {
      margin: 10px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .phone {
    font-size: 19px;
  }

  .email {
    font-size: 18px;
  }

  .visit-info {
    text-align: right;
    font-size: 16px;
    float: right;
    width: 50%;
    padding-bottom: 20px;

    p {
      margin: 7px 0;
    }
  }

  .logo {
    font-size: 32px;
    color: #2f9dc6;
    margin: 0 0 30px 0 !important;

    span {
      text-transform: uppercase;
      font-size: 25px;
    }
  }

  .doctor-name {
    text-transform: uppercase;
  }

  .bold {
    text-transform: none;
    font-weight: bold;
  }

  .line {
    height: 98px;
    background: #2f9dc6;
    border-top: 1px solid #74bed9;
    border-bottom: 1px solid #74bed9;
    width: 100%;
    position: relative;
    // left: 0;
    // right: 0;
    // bottom: 0;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .visibility-buttons {
    margin-top: 15px;
  }

  .action-button {
    background: none;
    border: 1px solid #2f9dc6;
    text-align: center;
    color: #2f9dc6;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
    height: 50px;
    padding: 15px;
    border-radius: 35px;

    &--round {
      padding: 0;
      width: 50px;
      border-radius: 50%;
    }

    &:hover,
    &:focus {
      background-color: #2f9dc6;
      color: white;
      outline: none;
    }

    &:active {
      outline: none;
      background-color: darken(#2f9dc6, 10%);
    }

    & + .action-button {
      margin-left: 10px;
    }
  }

  .checkbox-wrapper {
    display: inline-block;
    font-size: 18px;
    margin-right: 30px;
  }

  .toggle-report {
    i {
      display: none;
    }

    &:after {
      display: none !important;
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 46px;
      height: 46px;
      margin-right: 10px;
      background: url(./toggle-icon-transparent.png) no-repeat;
      background-size: contain;
    }
  }
}
